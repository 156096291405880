import Service from "../commons/AbstractServices";
import Contact from "../models/Contact";

export class ContactService extends Service<Contact> {

    static service: ContactService;

    static getInstance(): ContactService {
        if (!this.service) {
            this.service = new ContactService();
        }
        return this.service;
    }

    getURLBase() {
        return 'contact/';
    }

    updateTags(contact: Contact) {
        return super.put('update/tags', contact);
    }

}
