import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { AbstractComponent } from '../../commons/AbstractComponent';
import { Message } from '../../models/Message';
import { MessageService } from '../../services/MessageService';
import { LinkIcon } from '../LinkIcon/LinkIcon';
import { ModalContext, ModalRemove } from '../Modal/Modal';
import { Nav, NavItem } from '../Navbar/Navbar';
import {
  Button, Container,
  Content,
  FormGroup,
  InvalidFeedback,
  ListMessages,
  Toolbar,
  ButtonGroup,
  ListEmpty
} from './styles';
import listEmpty from './../../assets/images/listEmpty@2x.png';

interface Props {
  contact?: {},
  handleClosePage?: (event: React.MouseEvent<HTMLElement>) => void,
  handleQuickMessage?: any
}

export default class QuickMessage extends AbstractComponent<Props, {}> {

  messageService: MessageService = MessageService.getInstance();

  state = {
    title: '',
    message: '',
    messages: [],
    showForm: false
  }

  resetForm = () => {
    let resetState = {
      title: '',
      message: '',
      messages: [],
    }

    this.setState(resetState);
  }

  validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Campo obrigatório'),
    message: Yup.string()
      .required('Campo obrigatório')
  })

  componentDidMount = () => {
    this.getMessages();
  }

  getMessages = () => {
    this.messageService.findAllMessageQuick(this.getCodeCurrentChannelSession())
      .then((messages: Message[]) => {
        this.setState({ messages });
      });
  }

  deleteQuickMessage = (id: string) => {
    this.messageService.deleteQuickMessage(this.getCodeCurrentChannelSession(), id)
      .then(() => {
        this.alertService.success("Operação realizada com sucesso!");
        this.getMessages();
      })
      .catch((error) => {
        this.alertService.error("erro!")
      })
  }

  setShowForm = (showForm: boolean) => {
    this.setState({ showForm });
  }

  static contextType = ModalContext;
  context!: React.ContextType<typeof ModalContext>;

  render() {
    const { messages } = this.state;
    const { handleClosePage, handleQuickMessage } = this.props;
    const { handleOpenModal } = this.context

    return (
      <Container>
        <Toolbar>
          <h1>Mensagem rápida</h1>
          <Nav>
            <NavItem icon='Close' handleOnClick={handleClosePage} />
          </Nav>
        </Toolbar>
        <Content>

          {/* <HeaderDivider>Mensagens rápidas</HeaderDivider> */}
          <ListMessages>
            {messages && messages.length === 0 ? (<li>
              <ListEmpty>
                <img src={listEmpty} width="300" alt="Nenhum registro encontrado" />
                <h4>Nenhum registro cadastrado!</h4>
              </ListEmpty>
            </li>) : ''}
            {messages && messages.length > 0 && messages.map((item: any) => (
              <li key={item}>
                <div onClick={() => handleQuickMessage(item?.body)} >
                  <h5>{item?.title}</h5>
                  <small>{item?.body}</small>
                </div>
                <LinkIcon
                  icon='Close'
                  size={20}
                  padding={3}
                  handOnClick={() => handleOpenModal(
                    <ModalRemove handleModalClick={() => this.deleteQuickMessage(item._id!)} />
                  )} />
              </li>
            ))}
          </ListMessages>

          {
            !this.state.showForm && (
              <ButtonGroup>
                <Button primary type="button" onClick={() => this.setShowForm(true)}>Nova Mensagem Rápida</Button>
              </ButtonGroup>
            )
          }

          {
            this.state.showForm && (

              <Formik
                initialValues={{
                  title: '',
                  message: '',
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values, { resetForm }) => {
                  console.log('onsubmiting');
                  console.log(values);

                  const now = new Date();
                  let message = {
                    title: values.title,
                    body: values.message,
                    company: this.getCurrentUserCompany(),
                    channel: this.getCurrentChannel(),
                    scheduled: false,
                    timestamp: now,
                  };

                  this.messageService.saveMessageQuick(this.getCodeCurrentChannelSession(), message)
                    .then(() => {
                      this.getMessages();
                      this.alertService.success("Operação realizada com sucesso!");
                      this.setState({ showForm: false });
                      resetForm({});
                    }).catch((error) => {
                      this.alertService.error(error);
                    });

                }}>
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup error={errors.message} >
                      <label htmlFor="title">Título</label>
                      <input
                        name="title"
                        placeholder="Digite sua mensagem"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      <InvalidFeedback>
                        <ErrorMessage name="title" />
                      </InvalidFeedback>
                    </FormGroup>
                    <FormGroup error={errors.message} >
                      <label htmlFor="message">Mensagem</label>
                      <input
                        name="message"
                        placeholder="Digite sua mensagem"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      />
                      <InvalidFeedback>
                        <ErrorMessage name="message" />
                      </InvalidFeedback>
                    </FormGroup>
                    <Button primary type="submit" disabled={isSubmitting}>Salvar</Button>
                  </form>
                )}
              </Formik>
            )
          }

        </Content>
      </Container>
    )
  }
}