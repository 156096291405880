import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import { AbstractComponent } from '../../commons/AbstractComponent';
import { DateHelper } from '../../commons/DateHelper';
import Contact from '../../models/Contact';
import { Message } from '../../models/Message';
import { MessageService } from '../../services/MessageService';
import { LinkIcon } from '../LinkIcon/LinkIcon';
import { ModalContext, ModalRemove } from '../Modal/Modal';
import { Nav, NavItem } from '../Navbar/Navbar';
import { Button, Container, Content, FormGroup, InvalidFeedback, ListMessages, Toolbar, ButtonGroup, ListEmpty } from './styles';
import listEmpty from './../../assets/images/listEmpty@2x.png';

interface Props {
  contact?: Contact,
  handleClosePage?: (event: React.MouseEvent<HTMLElement>) => void
}

export default class ScheduleMessage extends AbstractComponent<Props, {}> {

  messageService: MessageService = MessageService.getInstance();

  state = {
    title: '',
    message: '',
    messages: [],
    startDate: new Date(),
    showForm: false
  }

  validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Campo obrigatório'),
    message: Yup.string()
      .required('Campo obrigatório'),
    startDate: Yup.string()
      .required('Campo obrigatório')
  })

  componentDidMount = () => {
    this.getScheduleMessages();
  }

  getScheduleMessages = () => {
    this.messageService.findAllMessageSchedule(this.getCodeCurrentChannelSession(), this.props?.contact?._id!!)
      .then((messages: Message[]) => {
        console.log(messages);
        this.setState({ messages });
      });
  }

  deleteScheduleMessage = (id: string) => {
    this.messageService.deleteScheduleMessage(this.getCodeCurrentChannelSession(), id)
      .then(() => {
        this.alertService.success("Operação realizada com sucesso!");
        this.getScheduleMessages();
      })
      .catch((error) => {
        this.alertService.error("erro!")
      })
  }

  setShowForm = (showForm: boolean) => {
    this.setState({ showForm });
  }

  static contextType = ModalContext;
  context!: React.ContextType<typeof ModalContext>;

  render() {
    const { handleClosePage } = this.props;
    const { handleOpenModal } = this.context;

    return (
      <Container>
        <Toolbar>
          <h1>Agendamento de mensagem</h1>
          <Nav>
            <NavItem icon='Close' handleOnClick={handleClosePage} />
          </Nav>
        </Toolbar>
        <Content>

          {/* <HeaderDivider>Mensagens agendadas</HeaderDivider> */}
          <ListMessages>
            {this.state.messages && this.state.messages.length === 0 ? (<li>
              <ListEmpty>
              <img src={listEmpty} width="300" alt="Nenhum registro encontrado" />
                <h4>Nenhum registro cadastrado!</h4>
              </ListEmpty>
            </li>) : ''}
            {this.state.messages.map((item: Message) => (
              <li key={item._id}>
                <div>
                  <h5>{item.title}</h5>
                  <p>{item.body}</p>
                  <small>{DateHelper.toFormartFromMiliseconds(item?.timestamp!!, 'dd/MM/yyyy HH:mm')}</small>
                </div>
                <LinkIcon
                  icon='Close'
                  size={20}
                  padding={3}
                  handOnClick={() => handleOpenModal(
                    <ModalRemove handleModalClick={() => this.deleteScheduleMessage(item?._id!!)} />
                  )} />
              </li>
            ))}
            <br />
            <br />
          </ListMessages>

          {
            !this.state.showForm && (
              <ButtonGroup>
                <Button primary type="button" onClick={() => this.setShowForm(true)}>Novo Agendamento</Button>
              </ButtonGroup>
            )
          }

          {
            this.state.showForm && (
              <Formik
                initialValues={this.state}
                validationSchema={this.validationSchema}
                onSubmit={(values, { resetForm }) => {

                  let message = {
                    title: values.title,
                    body: values.message,
                    company: this.getCurrentUserCompany(),
                    channel: this.getCurrentChannel(),
                    contact: this.props?.contact?._id,
                    scheduled: true,
                    timestamp: this.state.startDate.getTime(),
                  };

                  this.messageService.saveMessageSchedule(this.getCodeCurrentChannelSession(), message)
                    .then(() => {
                      this.alertService.success("Operação realizada com sucesso!");
                      this.getScheduleMessages();
                      resetForm({});
                      this.setState({ startDate: new Date(), showForm: false });
                    }).catch((error) => {
                      this.alertService.error(error);
                    });
                }}>
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup error={errors.message} >
                      <label htmlFor="email">Título</label>
                      <input
                        name="title"
                        placeholder="Digite sua mensagem"
                        onChange={handleChange}
                        value={values.title}
                      />
                      <InvalidFeedback>
                        <ErrorMessage name="message" />
                      </InvalidFeedback>
                    </FormGroup>
                    <FormGroup error={errors.message} >
                      <label htmlFor="email">Mensagem</label>
                      <input
                        name="message"
                        placeholder="Digite sua mensagem"
                        onChange={handleChange}
                        value={values.message}
                      />
                      <InvalidFeedback>
                        <ErrorMessage name="message" />
                      </InvalidFeedback>
                    </FormGroup>
                    <FormGroup error={errors.startDate}>
                      <label htmlFor="dateMessage">Data</label>
                      <DatePicker
                        name="startDate"
                        selected={this.state.startDate}
                        onChange={(date: Date) => this.setState({ startDate: date })}
                        dateFormat="dd/MM/yyyy hh:mm"
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={15}
                      />
                      <InvalidFeedback>
                        <ErrorMessage name="dateMessage" />
                      </InvalidFeedback>
                    </FormGroup>
                    <Button primary type="submit" disabled={isSubmitting}>Salvar</Button>
                  </form>
                )}
              </Formik>
            )
          }

        </Content>
      </Container>
    )
  }
}
