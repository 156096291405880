import { Company } from "./Company";

export class User {
    public _id?: any;
    public name?: string;
    public email?: string;
    public phone?: string;
    public image?: string;

    public admin?: boolean;
    public root?: boolean;
    public confirmed?: boolean;
    public blocked?: boolean;
    public removed?: boolean;

    public company?: Company | any;

    public token?: string;

    // timestamps
    public created_at?: Date;
    public updated_at?: Date;
}
