import React from 'react';
import { DateHelper } from '../../../commons/DateHelper';
import { Avatar, ConversationText, MessageContainer, MessageText, TimeMessage } from './styles';

const getTimeFormated = (timestamp: number) => {
  // const date = new Date(timestamp * 1000);
  // let options = {
  //   hour: 'numeric', minute: 'numeric',
  // };
  // return new Intl.DateTimeFormat('pt-BR', options).format(date);
  return DateHelper.toFormartFromMiliseconds(timestamp * 1000, 'HH:mm');
}

function ChatMessageItem(props: any) {
  const { odd, message, image } = props;
  const avatarDefault = "https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/user.jpeg?alt=media";
  return (
    <MessageContainer odd={odd}>
      {  !odd && (<Avatar src={image || avatarDefault} />)}
      { (message.hasMedia && message.type === 'ptt') && (
        <ConversationText>
          <MessageText>
            {
              message.chat.isGroup === true && (
                <span>
                  <strong>{message.contact.name || message.contact.pushname}</strong><br /><br />
                </span>
              )
            }
            <audio controls src={'data:audio/ogg;base64,' + message.media.data} />
          </MessageText>
          <TimeMessage>{getTimeFormated(message.timestamp)}</TimeMessage>
        </ConversationText>
      )}
      { (message.type === 'image' && message.hasMedia && message.media) && (
        <ConversationText>
          <MessageText>
            {
              message.chat.isGroup === true && (
                <span>
                  <strong>{message.contact.name || message.contact.pushname}</strong><br /><br />
                </span>
              )
            }
            <img src={'data:' + message.media.mimetype + ';charset=utf-8;base64,' + message.media.data} width="300" height="200" style={{ objectFit: 'contain' }} alt='img' />
          </MessageText>
          <TimeMessage>{getTimeFormated(message.timestamp)}</TimeMessage>
        </ConversationText>
      )}
      { (message.type === 'document' && message.hasMedia && message.media) && (
        <ConversationText>
          <MessageText>
            {
              message.chat.isGroup === true && (
                <span>
                  <strong>{message.contact.name || message.contact.pushname}</strong><br /><br />
                </span>
              )
            }
            Documento: <a href={'data:application/octet-stream;base64,' + message.media.data} download={message.media.filename}>{message.media.filename}</a>
          </MessageText>
          <TimeMessage>{getTimeFormated(message.timestamp)}</TimeMessage>
        </ConversationText>
      )}
      { message.type === 'chat' && (
        <ConversationText>
          <MessageText>
            {
              message.chat.isGroup === true && (
                <span>
                  <strong>{message.contact.name || message.contact.pushname}</strong><br />
                </span>
              )
            }
            {message.body}
          </MessageText>
          <TimeMessage>{getTimeFormated(message.timestamp)}</TimeMessage>
        </ConversationText>
      )}
    </MessageContainer>
  )
}

export default ChatMessageItem;