import Service from "../commons/AbstractServices";
import { Protocol } from "../models/Protocol";

export class ProtocolService extends Service<Protocol | any> {

    static service: ProtocolService;

    static getInstance(): ProtocolService {
        if (!this.service) {
            this.service = new ProtocolService();
        }
        return this.service;
    }

    getURLBase() {
        return 'protocol/';
    }

    getAllOpenedProtocols(idChannel: string): Promise<Array<Protocol>> {
        return super.get(`list/opened/${idChannel}`);
    }

    getOpenedByContact(idContact: string) {
        return super.get(`get/opened/contact/${idContact}`);
    }

    transferDepartment(id: string, idDepartment: string): Promise<Array<Protocol>> {
        return super.get(`transfer/${id}/department/${idDepartment}`);
    }

    transferOperador(id: string, idUser: string, idDepartment: string): Promise<Array<Protocol>> {
        return super.get(`transfer/${id}/operator/${idUser}/department/${idDepartment}`);
    }

}
