import React, { useState } from 'react';

import { NavContainer, NavItemContainer, NavItemLink, NavItemLinkText, DropdownContainer, 
  DropdownItemContainer, Avatar, DropdownItemTextMuted, DropdownItemHeading, DropdownItemHr } from './styles';

import {
  IconContainer, Icon, IconSearch, IconUserShared, IconDotsVerticalRounded,
  IconPower, IconPlane, IconPaperclip, IconCloseOutline, IconLogOut,
  ListHeader, ListItem
} from './styles';


interface Props {
  icon?: string,
  iconLeft?: string,
  iconRight?: string,
  label?: string,
  handleOnClick?: (event: React.MouseEvent<HTMLElement>) => void,
  children?: React.ReactNode | React.ReactNode[],
  style?: React.CSSProperties,
  size?: number,
  src?: string,
  avatar?: boolean,
  margin?: number,
}

const getIcon = (icon: string) => {
  switch (icon) {
    case 'Search':
      return <IconSearch />
    case 'UserShared':
      return <IconUserShared />
    case 'DotsVerticalRounded':
      return <IconDotsVerticalRounded />
    case 'Power':
      return <IconPower />
    case 'Plane':
      return <IconPlane />
    case 'Paperclip':
      return <IconPaperclip />
    case 'Close':
      return <IconCloseOutline />
    case 'LogOut':
        return <IconLogOut />
    default:
      return '';
  }
}

export function Nav(props: Props) {
  return (
    <NavContainer>
      <ul>{props.children}</ul>
    </NavContainer>
  )
}

export function NavItem(props: Props) {

  return (
    <NavItemContainer>
      <NavItemLink href="#" onClick={props.handleOnClick}>
        {props.icon && <IconContainer>{getIcon(props.icon)}</IconContainer>}
      </NavItemLink>
    </NavItemContainer>
  )
}

export function NavItemDropdown(props: Props) {

  const [open, setOpen] = useState(false);

  const handleDropdown = () => {
    setOpen(!open)
  }

  const handleBlur = () => {
    setTimeout(() => {
      setOpen(false)
    }, 200)
  }

  return (
    <NavItemContainer onBlur={handleBlur}>
      {props.icon && <NavItemLink href="#" onClick={handleDropdown} >
        <IconContainer>{getIcon(props.icon)}</IconContainer>
      </NavItemLink>}
      {props.avatar && <NavItemLinkText href="#" onClick={handleDropdown} >
        <Avatar size={props.size} src={props.src}/>
      </NavItemLinkText>}
      {open && props.children}
    </NavItemContainer>
  )
}

export function Dropdown(props: Props) {
  return (
    <DropdownContainer>
      {props.children}
    </DropdownContainer>
  )
}

export function DropdownItem(props: Props) {

  return (
    <DropdownItemContainer href="#" onClick={props.handleOnClick}>
      {props.iconLeft && <Icon size={props.size}>{getIcon(props.iconLeft)}</Icon>}
      {props.children && <span>{props.children}</span>}
      {props.iconRight && <Icon size={props.size} style={{ 'marginLeft': 'auto' }}>{getIcon(props.iconRight)}</Icon>}
    </DropdownItemContainer>
  )
}

export function DropdownItemText(props: Props) {

  return (
    <DropdownItemTextMuted>
      {props.children}
    </DropdownItemTextMuted>
  )
}

export function DropdownItemHeader(props: Props) {

  return (
    <DropdownItemHeading {...props}>
      {props.children}
    </DropdownItemHeading>
  )
}

export function DropdownItemDivider(props: Props) {

  return (
    <DropdownItemHr>
      {props.children}
    </DropdownItemHr>
  )
}

export function DropdownListHeader(props: Props) {

  return (
    <ListHeader>
      <h4>{props.children}</h4>
      <button onClick={props.handleOnClick}>Transferir</button>
    </ListHeader>
  )
}

export function DropdownListItem(props: Props) {

  return (
    <ListItem>
      {props.children}
    </ListItem>
  )
}