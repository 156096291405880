import { User } from "../models/User";

export default class UtilHelper {

    static no_image_user: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAD+ElEQVRoQ+2WS2gdZRTH/+ebSVLxRUVI1YXWtJUGm3QeN6m1+FipDbbiIohKtKa907QiUgQtuhAFNQsLPvC+6iOiuNBVcSUqtRqb5s7cGyNaI2hFN9aCj2pok8ycI9HNRZLmm8y9NYWZ7f2fc/6/8//uzEc4xx86x/0jBfi/E0wTSBNIuIH0CCVcYOLyNIHEK0zYIE0g4QITl9c9ge7SSGvExgCAHgHaGMTENEEkB6IWozC2zfo9seuaBnUFsItBr4iUFPOFpOhThhonEQWwDVIbwHwCSt0beO4H9YKoG4BbLN8lQu8wYdgIpd/flZmoNenmypYovMGgdgX01AuiLgCdubErTJmeIIOCC5r/uuXgtptPz7Xh7hdHLppuUp8phVZArQk894+kSdQFwC4EgwLZ06SiNaM7uo+dyZSVr7iKuAzgkcBzn18SAFZu9CjBOFYZcDbrGHIKZZ+ASd/L3KijP5MmeQIiZOWDGUPJoO9lHtcxZOVGS4C6vTrgrtDRNxzAKZZDgfFsxXOe0DHk5P0iiLcGXlerjr6xAACcgv8tQb7xvcwWHUPrXzkyQqYRVrPuJh19wwHsQrBPOHrQZLSVd3f9tMCfuFMRjwHYG3juc0sCwNpfuRIRHyWmYVKyOfDcmbmMdbz5xfnG5NQhQF21rGl69eHtG39dEgCzJuxicD+JvE6Qj0I2t48NWD/UmrPylXaDoiGB2ELG1krWeT+p+dn65G+hGhdOwb8vEsmRULNS8hULHScRIsJlEG4nkZMwjT4/mzlQD/N1A3AKfhsE/SC5A6C185tjZlI+Cb0Xsjk0PtD5S1KQRAlYr1YuN8JoUFjuYaUiEvkYpD40iKsE/lFm6OSswajZWE4sVxMkExHdpgRdzDhtKHmpxZx6erh/05+LBVk0gFPweyD8FgualaH2mRS+fGTHhuM6RuxSsJZEHoOgT0CvVTynX6duLs2iAP69ecrbEIwahty90P1nPnN2KXAkopWQMGcy1pV3d/0cFyQ2gF0KOiiMygLjUMupqS2H92w8FXdorf66/Z9fMhO1PBQCl/J55qPjfZ2TcfrFB8gFB0XJNWHU3P7lro7f4gybT+vmytdGkCG0mLdWH7BPxOkZC+Cfs8vyNQgPB1n3hTiDFtI6eX/2HmUHO907F9LW/h4LwC2UPQHlIzZW/vdDFWfoXNr1uepNSoXXV7LuMyAS3X6xAJyiv5cFT61a/v2yd3t7I90hOjorX7lBEX8CYFXgud/p1MT+kDkF/2IWtbq60/Z1B2jrnhRlraiuq3rWeMMS0DZzFoWxjtBZ9KU9KgXQXlWDhGkCDVqsdts0Ae1VNUiYJtCgxWq3TRPQXlWDhH8D9ol7QMAdMQsAAAAASUVORK5CYII=';

    public static getUserImage(user: User) {
        let image: string = this.no_image_user;
        if (user && user.image) {
            return user.image;
        }
        return image;
    }

    public static getTimeFormated(timestamp: number) {
        if (timestamp) {
            const date = new Date(timestamp * 1000);
            let options = {
                hour: 'numeric', minute: 'numeric',
            };
            return new Intl.DateTimeFormat('pt-BR', options).format(date);
        }
    }

}