import Service from "../commons/AbstractServices";
import { Chat } from "../models/Chat";

export class ChatService extends Service<Chat> {

    static service : ChatService;

    static getInstance() : ChatService {
        if (!this.service) {
            this.service = new ChatService();
        }
        return this.service;
    }

    getURLBase() {
    return 'chat/';
    }

    getChatsByChannel(idChannel: string) : Promise<Array<Chat>> {
        return super.get('list/' + idChannel);
    }

}
