import React from 'react'

import ImageContainer from './styles';
import LogoTrendBot from '../../assets/images/logo/logo.svg';
import LogoTrendBuild from '../../assets/images/trendbuild/trendbuild_4.png';
import LogoIcon from '../../assets/images/android-chrome-192x192.png';

interface Props {
  textAlign?: string,
  width?: string,
  height?: string,
  type?: string
}

const Logo = (props:Props) => {

  const {type, width, height} = props;
  let img;

  if (type === 'icon') {
    img = <ImageContainer src={LogoIcon} width={width} height={height} alt="Logo"/>
  } 
  if (type === 'bot') {
    img = <ImageContainer src={LogoTrendBot} width={width} height={height} alt="Logo"/>
  } else {
    img = <ImageContainer src={LogoTrendBuild} width={width} height={height} alt="Logo"/>
  }
  
  return (
    <>
      {img}
    </>
  )
}

export default Logo;
