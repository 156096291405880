import styled, { createGlobalStyle } from 'styled-components';


export default createGlobalStyle`

:root {
    --indigo: #685ba9;
    --purple: #6658dd;
    --red: #f1556c;
    --orange: #fd7e14;
    --yellow: #f7b84b;
    --green: #1abc9c;
    --teal: #02a8b5;
    --cyan: #4fc6e1;
    --white: #fff;
    --gray: #98a6ad;
    --gray-dark: #343a40;
    --zapteck: #0f222d;
    --primary: #09141a;
    --secondary: #6c757d;
    --success: #1abc9c;
    --info: #4fc6e1;
    --warning: #f7b84b;
    --danger: #f1556c;
    --light: #f1f5f7;
    --dark: #323a46;
    --pink: #f672a7;
    --blue: #4a81d4;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Nunito",sans-serif;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --toolbar-size: 70px;
  }

  *,
  ::before,
  ::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  }
  
  body {
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: sans-serif;
    line-height: 1.15;
    color: var(--secondary);
    background-color: var(--light);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smooth: antialiased;
  }

  form {
    width: 100%;
  }

  input, button {
    outline: 0
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0 0 1rem;
  }

  button {
    cursor: pointer;
  }
`;

interface Props {
  alignItems?: string,
  justifyContent?: string,
  color?: string,
  bg?: string,
  error?: any,
  noMargin?: boolean,
  noPadding?: boolean
}


export const Card = styled.div`
  width: 100%;
  padding: 2rem;
  border-bottom: 10px solid #F3F7F9;
  background-color: var(--white);
  position: relative;

  img {
    margin-bottom: 2rem;
  }

  h2,h3,h4,h5 {
    color: var(--primary);
    margin-bottom: 1rem;
  }

  h4 {
    font-weight:400;
    text-transform: uppercase; 
    color: var(--primary);
    margin-bottom: 1rem;
  }

  p {
    color: var(--gray);
    margin-bottom: .5rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: Props) => props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${(props: Props) => props.alignItems ? props.alignItems : 'center'};
`;
