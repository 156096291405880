import Service from "../commons/AbstractServices";
import { Pager } from "../commons/Pager";
import { Message } from "../models/Message";

export class MessageService extends Service<Message | any> {

    static service: MessageService;

    static getInstance(): MessageService {
        if (!this.service) {
            this.service = new MessageService();
        }
        return this.service;
    }

    getURLBase() {
        return 'message/';
    }

    getMessagesByChat(code: string, remote: string): Promise<Array<Message>> {
        return super.get(`list/${code}/${remote}`);
    }

    pagerMessageByChat(page: number, perPage: number, code: string, remote: string): Promise<Pager<Message>> {
        return super.get(`pager/${page}/${perPage}/${code}/${remote}`);
    }

    saveMessageQuick(code: string, message: any): Promise<any> {
        return super.post(`quick/${code}`, message);
    }

    getMessageQuick(code: string, idMessage: string): Promise<Message> {
        return super.get(`quick/get/${code}/${idMessage}`);
    }

    findAllMessageQuick(code: string): Promise<Array<Message>> {
        return super.get(`quick/list/${code}`);
    }

    saveMessageSchedule(code: string, message: Message): Promise<Message> {
        return super.post(`schedule/${code}`, message);
    }

    getMessageSchedule(code: string, idMessage: string): Promise<Message> {
        return super.get(`schedule/get/${code}/${idMessage}`);
    }

    findAllMessageSchedule(code: string, idContact: string): Promise<Array<Message>> {
        return super.get(`schedule/list/${code}/${idContact}`);
    }

    deleteQuickMessage(code: string, idMessage: string): Promise<any> {
        return this.api().delete(`${this.getURLBase()}delete/quick/${code}/${idMessage}`);
    }

    deleteScheduleMessage(code: string, idMessage: string): Promise<any> {
        return this.api().delete(`${this.getURLBase()}delete/schedule/${code}/${idMessage}`);
    }

}
