import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ChatScreen from './screens/chat/ChatScreen';
import LoginScreen from './screens/login/LoginScreen';
// import { isAuthenticated } from './services/AuthService';

// interface Props {
//     Component: React.FC<RouteComponentProps>
//     path: string;
//     exact?: boolean;
// };

// const PrivateRoute = ({ Component, path, exact = false }: Props) => (
//     <Route
//         exact={exact}
//         path={path}
//         render={(props: RouteComponentProps) =>
//             isAuthenticated() ? (
//                 <Component {...props} />
//             ) : (
//                     <Redirect to={{ pathname: "/", state: { from: props.location } }} />
//                 )
//         }
//     />
// );


const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={LoginScreen} />
            <Route path="/login" component={LoginScreen} />

            <Route path="/chats" component={ChatScreen} />
            {/* <Route path="/avaliacao/:codigo" component={AvaliacaoComponent} /> */}
            {/* <Route path="/painel/:codigo/senha/:idSenha" component={PainelComponent} /> */}

            {/* <PrivateRoute exact path="/atendimento" component={AtendimentoComponent} />
            <PrivateRoute exact path="/atendimento/franquia" component={AtendimentoFranquiaComponent} />
            <PrivateRoute exact path="/atendimento/chamada" component={AtendimentoChamadaComponent} />
            <PrivateRoute path="/filas/:codigo" component={FilasComponent} /> */}

        </Switch>
    </BrowserRouter>
);

export default Routes;