import styled from 'styled-components';

type Props = {
  size?: number
}

const ImageContainer = styled.img`
  --img-size: ${(props:Props) => props.size+'px' || '16px'};
  width: var(--img-size);
  height: var(--img-size);
  border-radius: 50%;
  object-fit: cover;
`;

export default ImageContainer;
