
import React from 'react';
import { Channel } from '../models/Channel';
import { User } from '../models/User';
import { AlertService } from "../services/AlertService";
import { Constants } from './Contants';
import UtilHelper from "./UtilHelper";

export class AbstractComponent<P, S> extends React.Component<P, S> {

    public alertService: AlertService = AlertService.getInstance();

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: P | any) {
        super(props);
    }

    getUsuarioImage(user: User) {
        return UtilHelper.getUserImage(user);
    }

    getCurrentUser(): User {
        const currentUser = JSON.parse(localStorage.getItem(Constants.CURRENT_USER) as any)
        return currentUser.user;
    }

    getCurrentUserCompany(): User {
        const currentUser = JSON.parse(localStorage.getItem(Constants.CURRENT_USER) as any)
        return currentUser.user.company;
    }

    getIDCurrentUserSession() {
        const currentUser = this.getCurrentUser();
        if (currentUser) {
            return currentUser._id;
        }
        return null;
    }

    getIDCompanyCurrentUserSession() {
        const currentUser = this.getCurrentUser();
        if (currentUser) {
            return currentUser.company._id;
        }
        return null;
    }

    getNameCurrentUserSession() {
        var currentUser = this.getCurrentUser();
        if (currentUser) {
            return currentUser.name;
        }
        return null;
    }

    getCurrentChannel(): Channel {
        return JSON.parse(localStorage.getItem(Constants.CURRENT_CHANNEL) as any);
    }

    getIDCurrentChannelSession(): string {
        const currentChannel = this.getCurrentChannel();
        if (currentChannel) {
            return `${currentChannel._id}`;
        }
        return ``;
    }

    getCodeCurrentChannelSession(): string {
        const currentChannel = this.getCurrentChannel();
        if (currentChannel) {
            return `${currentChannel.code}`;
        }
        return ``;
    }

}