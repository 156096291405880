import { Company } from "./Company";

export class Channel {

    public _id?: string;
    public name?: string;
    public code?: string;
    public token?: string;

    public status?: string;

    public blocked?: boolean;
    public removed?: boolean;

    public company?: Company;

    // timestamps
    public created_at?: Date;
    public updated_at?: Date;

}
