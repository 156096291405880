import styled, {css} from 'styled-components';

interface Props {
  odd?: boolean,
}

export const MessageContainer = styled.li`
  overflow-anchor: auto;
  width: 85%;
  height: auto;
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;

  ${(props: Props) => props.odd ? css`
  
    justify-content: flex-end;
    align-self: flex-end;

    div {
      background-color: var(--white);
      color: var(--primary);
      border-radius: 7px 7px 0 7px;

      small {
        left: unset;
        right: 0;
      }
    }
  ` : ''}

  @media (min-width: 700px){
    width: 75%;
  }
`;

export const Avatar = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 1rem;
  background-image: ${props => props.src ? props.src : './assets/images/users/user-6.jpg'};
`;

export const ConversationText = styled.div`
  position: relative;
  background-color: var(--primary);
  color: var(--white);
  padding: 1rem;
  border-radius: 7px 7px 7px 0;
`;

export const MessageText = styled.p`
  font-size: 1.5rem;
  word-break: break-all;
`;

export const TimeMessage = styled.small`
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 1rem;
  color: var(--gray);
`;

