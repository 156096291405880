import axios from 'axios';
import environment from '../environment';
import { Constants } from './Contants';

const api = axios.create({ baseURL: environment.apiUrl });

api.interceptors.request.use(async config => {
    const getCurrentUserPager = localStorage.getItem(Constants.CURRENT_USER)
    const currentUserPager: any = getCurrentUserPager ?
        JSON.parse(getCurrentUserPager) : '';
    const token = currentUserPager && currentUserPager.token ? currentUserPager.token : null;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

class Service<T> {

    getURLBase() {
    }

    api() {
        return api;
    }

    async get(url: string) {
        return await api.get(this.getURLBase() + url).then((response) => response.data);
    }

    async post(url: string, obj: T) {
        return await api.post(this.getURLBase() + url, obj).then((response) => response.data);
    }

    put(url: string, obj: T) {
        return api.put(this.getURLBase() + url, obj).then((response) => response.data);
    }

    getAllPager(page: number, perPage: number) {
        return api.get(this.getURLBase() + 'pager/' + page + '/' + perPage).then((response) => response.data);
    }

    getAllPagerSearch(page: number, perPage: number, search: string) {
        return api.get(this.getURLBase() + 'pager/search/' + page + '/' + perPage + '/' + search).then((response) => response.data);
    }

    getAll() {
        return api.get(this.getURLBase() + 'list').then((response) => response.data);
    }

    count() {
        return api.get(this.getURLBase() + 'count').then((response) => response.data);
    }

    getById(id: string) {
        return api.get(this.getURLBase() + id).then((response) => response.data);
    }

    getAllById(id: string) {
        return api.get(this.getURLBase() + id).then((response) => response.data);
    }

    create(obj: T) {
        return api.post(this.getURLBase() + 'create', obj).then((response) => response.data);
    }

    update(obj: T) {
        return api.put(this.getURLBase() + 'update', obj).then((response) => response.data);
    }

    delete(id: string) {
        return api.delete(this.getURLBase() + 'delete/' + id).then((response) => response.data);
    }

    handleError(error: any) {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
        return Promise.reject(error.response.data.message);
    }

}

export default Service;



