import styled from 'styled-components';

type Props = {
  activeOverview?: boolean,
  tipo?: string,
  bgColor?: string
}

export const ChatMessageWrapper = styled.div`
  position: relative;
  background-color: #F3F7F9;
  grid-column: 1;
  grid-row: 1; 
  z-index: 0;
  overflow: hidden;

  display: grid;
  grid-template-rows: 70px auto;

  @media (min-width: 700px) {
    grid-column: 2;
    z-index: 1;
  }
`;

export const Toolbar = styled.header`
  background-color: var(--primary);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1/3;
  grid-row: 1;
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 11;

  h1 {
    flex: 1 0 auto;
    font-size: 1.5rem;
    color: var(--white);
  }

  @media (min-width: 700px) {
    position: unset;
    height: unset;
  }
`;

export const BackButton = styled.a`
  color: white;
  font-size: 2rem;
  margin-right: -.5rem;
  display: block;
  cursor: pointer;
  
  @media (min-width: 700px) {
    display: none;
  }
`;

export const ChatMessageMain = styled.div`
  position: relative;
  width: 100%;
  height:100%;
  overflow: hidden;
  display: flex;
  grid-column: 1/3;
  grid-row: 2;

  @media (min-width: 700px) {
    z-index: 1;
    position: unset;
  }

`;

export const ChatMessageConversation = styled.div`
  width: 100%;
  height: 100%;
  
  display: grid;
  grid-template-rows: auto 60px;

  @media (min-width: 700px) {
    width: ${(props: Props) => props.activeOverview ? '50vw' : '75vw'};
    grid-template-rows: auto auto;
  }
`;

export const MessageContent = styled.div`
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  ul {
    padding: 2rem;
    flex-grow: 1;
    height: 80vh;
    overflow: scroll;

    display: flex;
    flex-direction: column;
  }
`;


export const MessageActionsContent = styled.div`
  border-top: 1px solid #ccc;
  display: block;
  height: 60px;

  @media (min-width: 700px) {
    height: auto;
  }
`;

export const RowRecordVoice = styled.div`
  grid-row: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  width: 100%;
  background-color: ${(props: Props) => props.bgColor ? props.bgColor : '#F3F7F9'};

  @media (min-width: 700px) {
    position: relative;
    bottom: unset;
  }
`;

export const ContainerEmoji = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;

  @media (min-width: 700px) {
    bottom: 50px;
    left: unset;
  }
`;

export const ContainerAttach = styled.div`
  position: absolute;
  bottom: 50px;
  left: 60px;

  background: #fff;
  width: 280px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0 5px 10px #efefef;


  @media (min-width: 700px) {
    bottom: 50px;
    left: 45px;
  }
`;

export const ContainerRecVoice = styled.div`
  position: absolute;
  bottom: 50px;
  right: 0;

  background: #fff;
  width: 400px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0 5px 10px #efefef;


  @media (min-width: 700px) {
    bottom: 50px;
    right: 0;
  }
`;

export const ContainerSecundaryMenu = styled.div`
  position: absolute;
  bottom: 50px;

  background: #fff;
  width: 280px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0 5px 10px #efefef;


  @media (min-width: 700px) {
    bottom: 50px;
    display: none;
    visibility: hidden;
  }
`;

export const RowMesgAct = styled.div`
  grid-row: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: .5rem;
  width: 100%;
  height: 60px;
  background-color: ${(props: Props) => props.bgColor ? props.bgColor : '#F3F7F9'};

  @media (min-width: 700px) {
    position: relative;
    bottom: unset;
    height: auto;
  }
  
  & > div {
    display: flex;
  }

  a, button {
    margin:5px;
  }

  input {
    width: 100%;
    flex: 1 auto 100%;
    padding: 1rem 2rem;
    color: var(--secondary);
    background-color: var(--white);
    border-radius: 2rem;
    border: 0;
    font-size: 1.6rem;
    margin:5px;

    &::placeholder {
      color: var(--gray);
    }
  }
`;

export const RowSecundaryMesgAct = styled.div`
  grid-row: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: .5rem;
  width: 100%;
  background-color: ${(props: Props) => props.bgColor ? props.bgColor : '#F3F7F9'};
  overflow-y: scroll;
  display: none;

  @media (min-width: 700px) {
    position: relative;
    bottom: unset;
    display: block;
  }
  
  div {
    display: flex;
  }

  a, button {
    margin:5px;
  }
`;

export const ChatOverview = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: var(--white);
  opacity: ${(props: Props) => props.activeOverview ? '1' : '0'};
  transition: all 0.4s ease;
  transform: ${(props: Props) => props.activeOverview ? 'translateX(0)' : 'translateX(600px)'};
  
  @media (min-width: 700px) {
    position: relative;
    width: ${(props: Props) => props.activeOverview ? '25vw' : '0'};
  }
`;

export const DateContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    
    display: contents;

    span{
      padding: 1rem;
      background: #fff;
      border-radius: 5px;
      margin: 1rem;
    }

    &:before, &:after{
      content: '';
      display: flex;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
  }
`;

export const ChatMessageEmpty = styled.div`
  position: relative;
  width: 100%;
  height:100%;
  overflow: hidden;
  display: flex;
  grid-column: 1/3;
  grid-row: 2;

  @media (min-width: 700px) {
    z-index: 1;
    position: unset;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img, h2 {
    margin-bottom: 2rem;
  }


`;
