import React, { useContext, useState } from 'react';
import ReactDOM from "react-dom";
import styled from 'styled-components';


export const ModalBackDropOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #323a46;
  transition: opacity .15s linear;
  opacity: .5;
  display: block;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  transition: opacity .15s linear;
  opacity: 1;
  display: block;
`;

export const ModalDialog = styled.div`
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
  max-width: 500px;
  margin: 1.75rem auto;
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: .2rem;
  outline: 0;
`;

export const ModalHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  
  h4 {
    color: #343a40;
    margin: 0;
  }

  button {
    float: right;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #343a40;
    text-shadow: none;
    opacity: .5;
    background-color: transparent;
    border: 0;
    font-size: 2.5rem;
  }
`;

export const ModalBodyStyled = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
`;

export const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .45rem .9rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: .5rem;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;
  margin-left: .5rem;

  background-color: ${(props: Props) => props.primary ? 'var(--primary)' : '#f1f5f7'};
  border-color: ${(props: Props) => props.primary ? '#0f222d' : '##f1f5f7'};
  color: ${(props: Props) => props.primary ? '#fff' : '#323a46'};
`;

export const ButtonBlock = styled(Button)`
  width: 100%;
  padding: 1rem;
`;

interface Props {
  children?: any,
  id?: string,
  primary?: boolean,
  handleModalClick?: any,
  handleModalClose?: any,
  onClick?: any
}

export const ModalContext = React.createContext<any>({});

export function ModalProvider (props: Props) {

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = React.useState("");

  const handleOpenModal = (content: any) => {
    setOpen(!open);
    content && setModalContent(content);
  }

  return (
    <ModalContext.Provider value={{ open, handleOpenModal, modalContent}}>
      {props.children}
    </ModalContext.Provider>
  )
}

export function ModalBackDrop(props: Props) {
  return (<ModalBackDropOverlay className="modal-backdrop"></ModalBackDropOverlay>)
}

export function Modals(props: Props) {

  const { open, modalContent } = useContext(ModalContext);

  const element = document.querySelector("#modal-root") || null;
  if (open && element) {
    return ReactDOM.createPortal(
      <><ModalBackDrop />
        <ModalWrapper tabIndex={-1} role="dialog" aria-hidden={!open}>
          <ModalDialog> 
            {modalContent}
          </ModalDialog>
        </ModalWrapper></>, element)
  } else return null;
}

export function Modal(props: Props) {
  return (<ModalContent {...props}>{props.children}</ModalContent>)
}

export function ModalHeader(props: Props) {
  const { handleOpenModal, open } = useContext(ModalContext) || null;
  return (
    <ModalHeaderStyled>
      <h4>{props.children}</h4>
      <button type="button" onClick={handleOpenModal} aria-hidden={!open}>×</button>
    </ModalHeaderStyled>
  )
}

export function ModalBody(props: Props) {
  return (
    <ModalBodyStyled>
      {props.children}
    </ModalBodyStyled>
  )
}

export function ModalFooter(props: Props) {
  return (
    <ModalFooterStyled>
      {props.children}
    </ModalFooterStyled>
  )
}

export function ModalButton(props: Props) {
  return (
    <Button {...props}>
      {props.children}
    </Button>
  )
}

export function ModalRemove(props: Props) {
  const { handleOpenModal } = useContext(ModalContext) || null;
  return (
    <Modal id={props.id}>
      <ModalHeader>Alerta</ModalHeader>
      <ModalBody>
        <p>Deseja Excluir o registro?</p>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={handleOpenModal}>Cancelar</ModalButton>
        <ModalButton onClick={() => {props.handleModalClick(); handleOpenModal()}} primary>Excluir</ModalButton>
      </ModalFooter>
    </Modal>
  )
}