import Service from "../commons/AbstractServices";
import { Constants } from "../commons/Contants";

export const isAuthenticated = () => localStorage.getItem(Constants.CURRENT_USER) !== null
export const getToken = () => localStorage.getItem(Constants.CURRENT_USER);

export class AuthenticationService extends Service<any> {

    static service: AuthenticationService;

    static getInstance(): AuthenticationService {
        if (!this.service) {
            this.service = new AuthenticationService();
        }
        return this.service;
    }

    getURLBase() {
        return '';
    }

    login(email: string, password: string) {
        return super.post('/auth', { email: email, password: password })
            .then((response: any) => {
                // login successful if there's a jwt token in the response
                let currentUserPager = response;
                if (currentUserPager && currentUserPager.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(Constants.CURRENT_USER, JSON.stringify(currentUserPager));
                    return Promise.resolve(currentUserPager);
                } else {
                    return Promise.reject(currentUserPager);
                }
            }).catch((err: any) => super.handleError(err));
    }

    logout() {
        localStorage.clear();
    }

}