import styled from 'styled-components';

interface Props {
  alignItems?: string,
  justifyContent?: string,
  color?: string,
  bg?: string,
  error?: any,
  noMargin?: boolean,
  noPadding?: boolean
}


export const Container = styled.div`
  background-color: var(--white);
  grid-column: 1;
  z-index: 0;
  transition: all .2s ease-out;

  @media (min-width: 600px) {
    grid-column: 2;
    z-index: 1;
    transform: translateX(0);
  }
`;

export const Toolbar = styled.header`
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1;
  height: 70px;

  h1 {
    font-size: 1.5rem;
    color: var(--primary);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  width: 100%;
  padding: 2rem;
  border-bottom: 10px solid #F3F7F9;
  background-color: var(--white);
  position: relative;

  img {
    margin-bottom: 2rem;
  }

  h2,h3,h4,h5 {
    color: var(--primary);
    margin-bottom: 1rem;
  }

  h4 {
    font-weight:400;
    text-transform: uppercase; 
    color: var(--primary);
    margin-bottom: 1rem;
  }

  p {
    color: var(--gray);
    margin-bottom: .5rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: Props) => props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${(props: Props) => props.alignItems ? props.alignItems : 'center'};
`;

export const ButtonGroup = styled.div`
  margin: .5rem;

  * {
    margin: .25rem;
  }
`;

export const Ribbon = styled.div`
  position: absolute;
  clear: both;
  padding: 5px 12px;
  margin-bottom: 15px;
  box-shadow: 2px 5px 10px rgba(50,58,70,.15);
  font-size: 13px;
  font-weight: 600;

  &:before {
    content: " ";
    border-style: solid;
    border-width: 10px;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    margin-bottom: -10px;
    z-index: -1;
    border-color: ${(props: Props) => props.bg ? props.bg : '#343a40'} transparent transparent;
  }
`;

export const RibbonLeft = styled(Ribbon)`
  float: left!important;  
  margin-left: -30px;
  border-radius: 0 3px 3px 0;

  background-color: ${(props: Props) => props.bg ? props.bg : '#343a40'};
  color: ${(props: Props) => props.color ? props.color : '#f3f7f9'};
`;

export const RibbonRight = styled(Ribbon)`
  float: right!important;  
  margin-right: -30px;
  border-radius: 3px 0 0 3px;

  background-color: ${(props: Props) => props.bg ? props.bg : '#343a40'};
  color: ${(props: Props) => props.color ? props.color : '#f3f7f9'};
`;

export const Badge = styled.span`
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: .5rem;

    background-color: ${(props: Props) => props.bg ? props.bg : '#343a40'};
    color: ${(props: Props) => props.color ? props.color : '#f3f7f9'};
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: ${(props: Props) => props.noMargin ? 0 : '3rem'};

  label {
    font-size: 1.4rem;
    display: block;
    margin-bottom: .5rem;
    color: ${(props: Props) => props.error ? 'var(--danger)' : 'var(--secondary)'};
  }

  input {
    display: block;
    width: 100%;
    height: calc(1.5em + .9rem + 2px);
    padding: 1.7rem 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--secondary);
    background-color: var(--white);
    background-clip: padding-box;
    border: ${(props: Props) => props.error ? '1px solid red' : '1px solid #ced4da'};
    border-radius: .5rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: ${(props: Props) => props.noMargin ? 0 : '1.5rem'};

    &:focus {
      color: #495057;
      background-color: var(--white);
      border-color: #b1bbc4;
      outline: 0;
      box-shadow: none;
    }

    &::placeholder{
      font-size: 1.4rem;
      color: #adb5bd;
      opacity: 1
    }
  }

  select {
    display: block;
    width: 100%;
    height: calc(1.5em + .9rem + 2px);
    padding: .45rem .9rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--secondary);
    background-color: var(--white);
    background-clip: padding-box;
    border: ${(props: Props) => props.error ? '1px solid red' : '1px solid #ced4da'};
    border-radius: .5rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 1.5rem;
  }
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: .5rem;
  font-size: 1.2rem;
  color: var(--danger);
`;