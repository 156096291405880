import styled from 'styled-components';

import { Search } from '@styled-icons/material';
import { UserShared } from '@styled-icons/remix-line';
import { DotsVerticalRounded } from '@styled-icons/boxicons-regular';
import { Power } from '@styled-icons/icomoon';
import { PaperPlane } from '@styled-icons/evaicons-solid';
import { Paperclip, LogOut } from '@styled-icons/feather';
import { CloseOutline } from '@styled-icons/evaicons-outline';


interface Props {
  white?: boolean,
  transparent?: boolean,
  size?: number,
  margin?: number,
  primary?: boolean,
}

export const IconSearch = styled(Search)``;
export const IconUserShared = styled(UserShared)``;
export const IconDotsVerticalRounded = styled(DotsVerticalRounded)``;
export const IconPower = styled(Power)``;
export const IconPlane = styled(PaperPlane)``;
export const IconPaperclip = styled(Paperclip)``;
export const IconCloseOutline = styled(CloseOutline)``;
export const IconLogOut = styled(LogOut)``;

export const NavContainer = styled.nav`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    justify-content: flex-end;
  }
`;

export const NavItemContainer = styled.li`
  //width: calc(var(--toolbar-size) * 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

export const NavItemLink = styled.a`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #454A50;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 300ms;

  &:hover {
    filter: brightness(1.2);
  }
`;

export const NavItemLinkText = styled.a`
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 300ms;

  &:hover {
    filter: brightness(1.2);
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  right: 0;
  
  border-radius: 10px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
  z-index: 999;

  background-color: #fff;
  color: #454A50;

  transition: all 0.4s ease;

  @media (min-width: 700px) {
    width: 300px;
    right: unset;
    transform: translate(-45%);
  }
`;

export const DropdownItemContainer = styled.a`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background 500ms;
  padding: 0.5rem;
  background-color: #fff;
  color: #6c757d;
  transition: filter 300ms;

  &:hover {
    background-color: #f3f7f9;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

export const IconContainer = styled.i`
  display:flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 7px;
    background-color: ${(props: Props) => props.transparent ? 'var(--primary)' : '#454A50'};
    color: var(--white);
  }
`;

export const Icon = styled.i`
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  svg {
    --img-size: ${(props: Props) => props.size + 'px' || '16px'};
    width: var(--img-size);
    height: var(--img-size);
    padding: auto;
    background-color: inherit;
    color: inherit;
  }
`;

export const Avatar = styled.img`
  --img-size: ${(props: Props) => props.size + 'px' || '16px'};
  width: var(--img-size);
  height: var(--img-size);
  border-radius: 50%;
`;

export const AvatarLetter = styled.span`
  --img-size: ${(props: Props) => props.size + 'px' || '16px'};
  width: var(--img-size);
  height: var(--img-size);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b1c2d9;
  color: #fff;
`;

export const DropdownItemTextMuted = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding: 0.5rem;
  height: 50px;
  color: #6c757d;
`

export const DropdownItemHeading = styled.h5`
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5rem;
  color: #6c757d;
  margin: 2rem 0 0;
`;

export const DropdownItemHr = styled.hr`
  height: 1px;
  overflow: hidden;
  border: 0;
  background-color: #efefef;
`;

export const ListHeader = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 1rem 0;
  border-bottom: 1px solid #dee2e6;

  h4 {
    font-size: 1.6rem;
    color: var(--primary);
    margin: 0;
  }

  button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #d2ddec;
    padding: .5rem .75rem;
    font-size: 1.3rem;
    line-height: 1.5;
    border-radius: .375rem;
    color: var(--gray);
    background-color: #fff;
  }

`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &::not(:last-child) {
    border-bottom: 1px solid #dee2e6;
  }

  img {
    margin-right: 1rem;
  }

  div {
    flex: 1 0 auto;
  }

  h4 {
    font-size: 1.4rem;
    color: var(--primary);
    margin: 0;
  }

  span {
    font-size: 1.2rem;
  }

  button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #d2ddec;
    padding: .5rem .75rem;
    font-size: 1.3rem;
    line-height: 1.5;
    border-radius: .375rem;
    color: var(--gray);
    background-color: #fff;

    &:hover {
      background-color: #f9fbfd;
      border-color: #d2ddec;
    }
  }
`;





