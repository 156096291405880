import React from 'react'

import ImageContainer from './styles';

interface Props {
  size?: number,
  src?: string
}

const Avatar = (props:Props) => {

  const {size, src} = props;
  return (
    <ImageContainer {...props} size={size} src={src} />
  )
}

export default Avatar;
