import styled, { css } from 'styled-components';

interface Props {
  left?: boolean,
  right?: boolean,
  color?: string,
  bg?: string
}


export const ChatItemContainer = styled.li`
  width: 100%;
  height: auto;
`;

export const ChatItemLink = styled.a`
  text-decoration: none;
  padding-left: 2rem;
  
  display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: auto;
  gap: 0 1rem;

  align-items: center;

  &:hover {
    background-color: var(--light);
  }
`;

export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: ${props => props.src ? props.src : './assets/images/users/user-6.jpg'};
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 2rem 2rem 2rem 0;
  color: #232323;
  border-bottom: 1px solid #efefef;
`;

const alignLeft = css`
  text-align: left;
`;

const alignRight = css`
  text-align: right;
`;

export const Column = styled.div`
  ${(props:Props) => (props.left ? alignLeft : '')};
  ${(props:Props) => (props.right ? alignRight : '')};

  margin-left: ${(props:Props) => props.right ? '1rem' : '0'};
`;

export const NameText = styled.h5`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .25rem;
`;

export const LastMessage = styled.span`
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--gray);
  display: block;
  margin-bottom: .5rem;
  word-break: break-all;
`;

export const TimeMessage = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--gray);
  text-align: right;
`;

export const LabelMessage = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: var(--success);
  color: var(--white);
  text-align: center;
  line-height: 1.7;
  margin: .35rem 0 .35rem auto;
`;

export const Badge = styled.span`
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: .5rem;

    background-color: ${(props:Props) => props.bg ? props.bg : '#343a40'};
    color: ${(props:Props) => props.color ? props.color : '#f3f7f9'};
`;

export const BadgeNoMargin = styled(Badge)`
    margin: 0
`;