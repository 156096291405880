import styled, { css } from 'styled-components';
import { Search, Email, EmojiEmotions, Refresh } from '@styled-icons/material';
import { UserShared } from '@styled-icons/remix-line';
import {
  DotsVerticalRounded, Check, PurchaseTag, PurchaseTagAlt,
  Tag, Bookmark, Calendar, Image
} from '@styled-icons/boxicons-regular';
import { Power } from '@styled-icons/icomoon';
import { PaperPlane } from '@styled-icons/evaicons-solid';
import { Paperclip } from '@styled-icons/feather';
import { CloseOutline } from '@styled-icons/evaicons-outline';
import { Microphone, Phone } from '@styled-icons/boxicons-solid/';

interface Props {
  bgColor?: string,
  color?: string,
  size?: number,
  padding?: number,
  bg?: string,
  hiddenDesktop?: boolean
}

export const IconContainer = styled.i`
  display:flex;
  justify-content: center;
  align-items: center;
  
  svg {
    border-radius: 50%;
    width: ${(props: Props) => props.size ? props.size + 'px' : '32px'};
    height: ${(props: Props) => props.size ? props.size + 'px' : '32px'};
    padding: ${(props: Props) => props.padding ? props.padding + 'px' : '7px'};
    background-color: ${(props: Props) => props.bgColor ? props.bgColor : '#454A50'};
    color: ${(props: Props) => props.color ? props.color : '#fff'};
    border: 1px solid ${(props: Props) => props.color ? props.color : '#fff'};
  }
`;

export const Link = styled.a`
  cursor: pointer;

  ${(props: Props) => props.hiddenDesktop ? css`
  
    @media (min-width: 700px) {
      display: none;
    }

  ` : ''}
`;

export const Button = styled.button`
  border: 0;
  background: transparent;
`;

export const ButtonTextLeft = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${(props: Props) => props.bg ? props.bg : '#fff'};
  padding: .5rem 1.5rem .5rem .5rem;
  border-radius: 2rem; 

  i, 
  img, 
  svg {
    margin-right: 0.5rem;
  }
`;



export const IconSearch = styled(Search)``;
export const IconUserShared = styled(UserShared)``;
export const IconDotsVerticalRounded = styled(DotsVerticalRounded)``;
export const IconPower = styled(Power)``;
export const IconPlane = styled(PaperPlane)``;
export const IconPaperclip = styled(Paperclip)``;
export const IconCloseOutline = styled(CloseOutline)``;
export const IconCheck = styled(Check)``;
export const IconMicrophone = styled(Microphone)``;
export const IconPhone = styled(Phone)``;
export const IconEmail = styled(Email)``;
export const IconPurchaseTag = styled(PurchaseTag)``;
export const IconTagAlt = styled(PurchaseTagAlt)``;
export const IconTag = styled(Tag)``;
export const IconBookmark = styled(Bookmark)``;
export const IconCalendar = styled(Calendar)``;
export const IconEmoji = styled(EmojiEmotions)``;
export const IconImage = styled(Image)``;
export const IconReload = styled(Refresh)``;






