import styled from 'styled-components';
import {Check} from '@styled-icons/boxicons-regular';

interface Props {
  muted?: boolean,
  textAlign?: string,
  primary?: boolean,
  error?: any
}

export const Container = styled.div`
  background-color: var(--white);
  grid-column: 1;
  z-index: 0;
  transition: all .2s ease-out;
  height: calc(100% - 70px);

  @media (min-width: 600px) {
    grid-column: 2;
    z-index: 1;
    transform: translateX(0);
  }
`;

export const Toolbar = styled.header`
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  h1 {
    font-size: 1.5rem;
    color: var(--primary);
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  form {
    padding: 2rem;
    border-top: 10px solid #f1f5f7;
  }
`;

export const FormGroup = styled.div`

  margin-bottom: 3rem;

  label {
    font-size: 1.4rem;
    display: block;
    margin-bottom: .5rem;
    color: ${(props: Props) => props.error ? 'var(--danger)' : 'var(--secondary)'};
  }

  input {
    display: block;
    width: 100%;
    height: calc(1.5em + .9rem + 2px);
    padding: 1.7rem 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--secondary);
    background-color: var(--white);
    background-clip: padding-box;
    border: ${(props: Props) => props.error ? '1px solid red' : '1px solid #ced4da'};
    border-radius: .5rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 1.5rem;

    &:focus {
      color: #495057;
      background-color: var(--white);
      border-color: #b1bbc4;
      outline: 0;
      box-shadow: none;
    }

    &::placeholder{
      font-size: 1.4rem;
      color: #adb5bd;
      opacity: 1
    }
  }
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: .5rem;
  font-size: 1.2rem;
  color: var(--danger);
`;

export const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .45rem .9rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: .5rem;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;

  background-color: ${(props: Props) => props.primary ? 'var(--primary)' : 'var(-dark)'};
  border-color: ${(props: Props) => props.primary ? '#0f222d' : '#dee2e6'};
  color: ${(props: Props) => props.primary ? '#fff' : '#dee2e6'};

  width: 100%;
  padding: 1rem;
`;

export const HeaderDivider = styled.h2`
  text-transform: uppercase;
  padding: .75rem;
  margin: 1.5rem 0;
  background-color: #f1f5f7!important;
  color: #343a40;
  font-size: 1.5rem;
`;

export const ListTags = styled.ul`

  overflow-y: scroll;
  padding: 2rem;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  a {
    flex: 1 0 auto;
    padding: 1rem 2rem;
    color: white;
    border-radius: .5rem;
    font-size: 1.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;

    svg {
      fill: white;
    }
  }
`;

export const PickerContainer = styled.div`

  margin-bottom: 2rem;

  input {
    height: 30px;
  }
`;

export const IconCheck = styled(Check)`
  width: 18px;
  height: 18px;
`; 

export const ButtonGroup = styled.div`
  padding: 2rem;
`;

export const ListEmpty = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-top: 2rem;
  }
`;

