
import { toast, ToastOptions } from 'react-toastify';

export class AlertService {

    public static service: AlertService;

    public static getInstance(): AlertService {
        if (!this.service) {
            this.service = new AlertService();
        }
        return this.service;
    }

    public options = {
        position: 'bottom-right'
    } as ToastOptions;

    public info(message: string) {
        toast.info(message, this.options);
    }

    public success(message: string) {
        toast.success(message, this.options);
    }

    public warning(message: string) {
        toast.warning(message, this.options);
    }

    public dark(message: string) {
        toast.dark(message, this.options);
    }

    public error(message: string) {
        toast.error(message, this.options);
    }

}
