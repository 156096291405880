import { Formik } from 'formik';
import React from 'react';
import { AbstractComponent } from '../../commons/AbstractComponent';
import Contact from '../../models/Contact';
import Tag from '../../models/Tag';
import { ContactService } from '../../services/ContactService';
import { TagService } from '../../services/TagService';
import Avatar from '../Avatar/Avatar';
import { ButtonIcon } from '../LinkIcon/LinkIcon';
import { Nav, NavItem } from '../Navbar/Navbar';
import { Chat } from './../../models/Chat';
import {
  Badge, ButtonGroup, Card,
  CardContent, Container,
  Content,






  FormGroup, RibbonLeft, Toolbar
} from './styles';



interface Props {
  chat?: Chat,
  handleClosePage?: (event: React.MouseEvent<HTMLElement>) => void
}

const avatarDefault = "https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/user.jpeg?alt=media";

export default class Perfil extends AbstractComponent<Props, {}> {

  tagService: TagService = TagService.getInstance();
  contactService: ContactService = ContactService.getInstance();

  state = {
    tags: [],
  }

  componentDidMount = () => {
    this.getTags();
  }

  populateContact = (contact: Contact) => {
    let con = new Contact();
    
    con._id = contact._id;
    con.name = contact.name;
    con.email = contact.email;
    con.number = contact.number;
    con.image = contact.image;
    con.origin = contact.origin;
    con.kindOf = contact.kindOf;
    con.company = contact.company;
    con.channel = contact.channel;
    con.tags = contact.tags;
    con.whatsapp = contact.whatsapp;
    con.removed = contact.removed;
    con.created_at = contact.created_at;
    con.updated_at = contact.updated_at;

    return con;
}

getTags = () => {
  this.tagService.getAllById(this.getIDCurrentChannelSession())
    .then((tags: Tag[]) => {
      this.setState({ tags })
    })
}

render() {
  const { chat, handleClosePage } = this.props;
  const { tags } = this.state;
  console.log(chat);
  return (
    <Container>
      <Toolbar>
        <h1>Dados do contato</h1>
        <Nav>
          <NavItem icon='Close' handleOnClick={handleClosePage} />
        </Nav>
      </Toolbar>
      <Content>
        <Formik
          initialValues={{
            kindOfContact: '',
            origin: ''
          }}
          onSubmit={(values) => {
            console.log(values);
            let isSubmitting:boolean = false;
            let contact = new Contact();
            contact = this.populateContact(chat?.contact!!);

            if(values.kindOfContact) {
              let kindOf: Tag = tags.filter((tag:Tag) => tag._id === values.kindOfContact)[0];
              contact.kindOf = kindOf;
              isSubmitting = true;
            }
            if(values.origin) {
              let origin: Tag = tags.filter((tag:Tag) => tag._id === values.origin)[0];
              contact.origin = origin;
              isSubmitting = true;
            }

            if(isSubmitting) {
              this.contactService.update(contact)
                .then(() => {
                  this.alertService.success("Operação realizada com sucesso!");
                })
                .catch((error) => {
                  this.alertService.error(error);
                })
            }

          }}>
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onChange={handleSubmit}>
              <Card>
                {chat?.contact?.kindOf && (
                  <RibbonLeft color="#fff" bg={chat.contact?.kindOf.color}>{chat.contact?.kindOf.name}</RibbonLeft>
                )}
                <CardContent>
                  {chat?.avatar ?
                    <Avatar size={150} src={chat.avatar} /> :
                    <Avatar size={150} src={avatarDefault} />}
                  <h2>{chat?.name}</h2>
                  <p>{chat?.contact?.number}</p>
                  <ButtonGroup>
                    <ButtonIcon icon="Phone"></ButtonIcon>
                    <ButtonIcon icon="Email"></ButtonIcon>
                    <ButtonIcon icon="Bookmark"></ButtonIcon>
                  </ButtonGroup>
                </CardContent>
              </Card>
              <Card>
                <CardContent justifyContent="flex-start" alignItems="flex-start">
                  <h4>Tipo de contato</h4>

                  <FormGroup noMargin>
                    <select
                      name="kindOfContact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={chat?.contact?.kindOf?._id}>
                      <option>Selecione...</option>
                      {tags?.map((tag: Tag) => tag && (
                        <option key={tag._id} value={tag._id}>{tag.name}</option>
                      ))}
                    </select>
                  </FormGroup>
                </CardContent>
              </Card>
              <Card>
                <CardContent justifyContent="flex-start" alignItems="flex-start">
                  <h4>Origem</h4>
                  <FormGroup noMargin>
                    <select
                      name="origin"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={chat?.contact?.origin?._id}>
                      <option>Selecione...</option>
                      {tags?.map((tag: Tag) => tag && (
                        <option key={tag._id} value={tag._id}>{tag.name}</option>
                      ))}
                    </select>
                  </FormGroup>
                </CardContent>
              </Card>
              <Card>
                <CardContent justifyContent="flex-start" alignItems="flex-start">
                  <h4>Etiquetas</h4>
                  {chat?.contact?.tags?.map((tag: Tag) => tag && (
                    <Badge key={tag._id} color="#fff" bg={tag.color}>{tag.name}</Badge>
                  ))}
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  )
}
}