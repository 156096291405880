import escapeRegExp from "escape-string-regexp";
import React from "react";
import ContentLoader from "react-content-loader";
import { isSafari } from "react-device-detect";
import { RouteComponentProps } from "react-router-dom";
import { BehaviorSubject, Observable } from "rxjs";
import io from "socket.io-client";
import { AbstractComponent } from "../../commons/AbstractComponent";
import { messaging } from "../../commons/InitFCM";
import { Pager } from "../../commons/Pager";
import { ChatItem } from "../../components/Chat/ChatItem/ChatItem";
import ChatMessage from "../../components/Chat/ChatMessage/ChatMessage";
import Logo from "../../components/Logo/Logo";
import environment from "../../environment";
import { Channel } from "../../models/Channel";
import { Chat } from "../../models/Chat";
import { Message } from "../../models/Message";
import { Protocol } from "../../models/Protocol";
import { AlertService } from "../../services/AlertService";
import { ChannelService } from "../../services/ChannelService";
import { ChatService } from "../../services/ChatService";
import { MessageService } from "../../services/MessageService";
import { ProtocolService } from "../../services/ProtocolService";
import { UserService } from "../../services/UserService";
import chatEmpty from './../../assets/images/chatEmpty@2x.png';
import {
  ChatEmpty, ChatList,
  Chats,
  ChatWrapper,
  Content,
  Icon,
  InputSearch,
  Toolbar
} from "./styles";


export default class ChatScreen extends AbstractComponent<
  RouteComponentProps,
  {}
> {
  chatService: ChatService = ChatService.getInstance();
  userService: UserService = UserService.getInstance();
  channelService: ChannelService = ChannelService.getInstance();
  messageService: MessageService = MessageService.getInstance();
  protocolService: ProtocolService = ProtocolService.getInstance();
  alertService: AlertService = AlertService.getInstance();

  state = {
    socket: io(environment.baseUrl, {}),
    socketConnected$: new BehaviorSubject(false),
    user: {},
    channels: [],
    contacts: [],
    chatSelected: new Chat(),
    chats: [],
    messages: new Array<Message>(),
    query: "",
    isChatMessage: false,
    mapSockets: new Map<string, boolean>(),
    protocols: new Array<Protocol>(),
    isContentLoad: false,
    // pagination
    page: 1,
    perPage: 10,
    total: 0,
  };

  requestPermission = async () => {
    if (!isSafari) {
      messaging
        .requestPermission()
        .then(async () => {
          const token = await messaging.getToken();
          console.log(token);
          this.userService.updateToken(this.getIDCurrentUserSession(), token);
        })
        .catch((err: any) => {
          console.log("Unable to get permission to notify.", err);
        });
      navigator.serviceWorker.addEventListener("message", (message) =>
        console.log(message)
      );
    }
  };

  componentDidMount = async () => {
    this.requestPermission();
    this.setState({ user: super.getCurrentUser() });
    this.getAllChannels();
    this.getChatByChannel();
  };

  getAllChannels = () => {
    this.channelService
      .getChannelsByCompany(this.getIDCompanyCurrentUserSession())
      .then((channels: Array<Channel>) => {
        this.configSocket();
        this.setState({ channels });
      });
  };

  getProtocolOpenedFromContact = (idContact: string) => {
    return (
      this.state.protocols.filter(
        (protocol: Protocol) => protocol?.contact?._id === idContact
      )[0] || null
    );
  };

  getAllOpenedProtocols = () => {
    this.protocolService
      .getAllOpenedProtocols(this.getIDCurrentChannelSession())
      .then((protocols: Array<Protocol>) => {
        this.setState({ protocols });
      });
  };

  getChatByChannel = () => {
    this.toggleContentLoad();
    this.chatService
      .getChatsByChannel(this.getIDCurrentChannelSession())
      .then((chats: Array<Chat>) => {
        console.log(chats);
        this.getAllOpenedProtocols();
        this.setState({ chats });
        if (!chats || chats.length === 0) {
          this.alertService.warning("Nenhum chat encontrado!");
        }
        this.toggleContentLoad();
      });
  };

  getMessages = () => {
    const { chatSelected, page, perPage, messages } = this.state;
    const remote = chatSelected.whatsapp?.id?._serialized;
    this.messageService
      .pagerMessageByChat(
        page,
        perPage,
        this.getCodeCurrentChannelSession(),
        remote
      )
      .then((pager: Pager<Message>) => {
        // console.log(pager);

        let list = pager.list;
        list.reverse();
        list.push(...messages);

        this.setState({
          page: pager.page,
          total: pager.total,
          messages: list,
        });

        if (page === 1) {
          // this.sendSeen(chatSelected);
          this.configSocketByChat(remote);
        }
      });
  };

  hasMoreMessages = (): boolean => {
    const { total, messages } = this.state;
    return messages.length < total;
  };

  getMoreMessages = () => {
    this.setState({ page: +this.state.page + 1 }, () => {
      this.getMessages();
    });
  };

  getProtocol = () => {
    const { chatSelected } = this.state;
    const remote = chatSelected.whatsapp?.id?._serialized;
    this.messageService
      .getMessagesByChat(this.getCodeCurrentChannelSession(), remote)
      .then((messages: Message[]) => {
        this.setState({ messages });
        this.configSocketByChat(remote);
      });
  };

  configSocket = () => {
    this.setState({ socket: io(environment.baseUrl + "/channel", {}) });

    this.state.socket.on("connect", () =>
      this.state.socketConnected$.next(true)
    );
    this.state.socket.on("disconnect", () =>
      this.state.socketConnected$.next(false)
    );

    const code: string = super.getCodeCurrentChannelSession();
    this.listen(`event_chats_${code}`).subscribe((data) => {
      // console.log(`event_chats_${code}`);
      this.getChatByChannel();
    });

    this.state.socketConnected$.asObservable().subscribe((connected) => {
      //console.log('Socket connected: ', connected);
    });
  };

  configSocketByChat = (remote: string) => {
    const code: string = super.getCodeCurrentChannelSession();
    const mapSockets: Map<string, boolean> = this.state.mapSockets;
    if (!mapSockets.has(remote)) {
      // console.log('configSocketByChat >> ' + remote);
      mapSockets.set(remote, true);
      this.setState({ mapSockets });

      this.listen(`event_channel_message_${code}_${remote}`).subscribe(
        (data: any) => {
          const { chatSelected }: any = this.state;
          console.log(`event_channel_message_${code}_${remote}`);
          console.log(data);
          console.log('chatSelected');
          console.log(chatSelected);
          if (data.from === chatSelected.whatsapp.id._serialized || data.to === chatSelected.whatsapp.id._serialized) {
            let messagesNew = this.state.messages;
            messagesNew.push(data as Message);
            this.setState({ messages: messagesNew });
            this.sendSeen(chatSelected);
          }
        }
      );
    }
  };

  listen = (event: string) => {
    return new Observable((observer) => {
      this.state.socket.on(event, (data: any) => {
        observer.next(data);
      });
      return () => {
        this.state.socket.off(event);
      };
    });
  };

  selectedChat = (chat: Chat) => {
    this.setState(
      {
        chatSelected: chat,
        isChatMessage: !this.state.isChatMessage,
        messages: new Array<Message>(),
        page: 1,
        perPage: 10,
        total: 0,
      },
      () => {
        this.getMessages();
      }
    );
  };

  sendSeen = (chat: Chat) => {
    this.channelService.sendSeen(
      this.getCodeCurrentChannelSession(),
      chat?.whatsapp?.id?._serialized!!
    );
  };

  updateQuery = (query: string) => {
    this.setState({ query: query.trim() });
  };

  clearQuery = () => {
    this.setState({ query: "" });
  };

  isChatMessage = () => {
    this.setState({
      isChatMessage: !this.state.isChatMessage,
    });
  };

  logout = () => {
    localStorage.clear();
    this.props.history.push(`/`);
  };

  toggleContentLoad = () => {
    this.setState({isContentLoad: !this.state.isContentLoad})
  }

  render() {
    const { chats, chatSelected, query, isChatMessage, messages, isContentLoad } = this.state;

    let showingChats;

    if (query) {
      const match = new RegExp(escapeRegExp(query), "i");
      showingChats = chats.filter((chat: any) => match.test(chat.name));
    } else {
      showingChats = chats;
    }
    //showingChats.sort(sortBy('name'));

    const MyLoader = () => (
      <ContentLoader
        speed={2}
        width={400}
        height={460}
        viewBox="0 0 400 460"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{marginTop: '50px'}}
      >
        <circle cx="55" cy="40" r="32" />
        <rect x="98" y="11" rx="0" ry="0" width="274" height="22" />
        <rect x="98" y="46" rx="0" ry="0" width="145" height="18" />
        <circle cx="55" cy="133" r="32" />
        <rect x="98" y="103" rx="0" ry="0" width="274" height="22" />
        <rect x="98" y="139" rx="0" ry="0" width="145" height="18" />
        <circle cx="55" cy="224" r="32" />
        <rect x="98" y="196" rx="0" ry="0" width="274" height="22" />
        <rect x="98" y="231" rx="0" ry="0" width="145" height="18" />
        <circle cx="55" cy="312" r="32" />
        <rect x="98" y="282" rx="0" ry="0" width="274" height="22" />
        <rect x="98" y="317" rx="0" ry="0" width="145" height="18" />
        <circle cx="55" cy="401" r="32" />
        <rect x="98" y="371" rx="0" ry="0" width="274" height="22" />
        <rect x="98" y="406" rx="0" ry="0" width="145" height="18" />
        <circle cx="55" cy="493" r="32" />
        <rect x="98" y="463" rx="0" ry="0" width="274" height="22" />
        <rect x="98" y="498" rx="0" ry="0" width="145" height="18" />
      </ContentLoader>
    );

    return (
      <ChatWrapper>
        <ChatList>
          <Toolbar>
            <Logo type="bot" height="40" />
          </Toolbar>
          <Content>
            {isContentLoad ? <MyLoader /> : (
              <>
                {chats.length === 0 ? 
                  <ChatEmpty>
                    <img src={chatEmpty} width="300" alt="Nenhum chat encontrado" />
                    <h4>Nenhum chat encontrado!</h4>
                  </ChatEmpty> :
                  <>
                    <InputSearch>
                      <input
                        type="text"
                        placeholder="Pesquisar…"
                        value={query}
                        onChange={(event) => this.updateQuery(event.target.value)}
                      />
                      <Icon size={24} />
                      {/* <button>Filter</button> */}
                    </InputSearch>
                    <Chats>
                      {showingChats.map((chat: Chat, index: number) => (
                          <ChatItem
                            key={chat._id}
                            chat={chat}
                            protocol={this.getProtocolOpenedFromContact(
                              chat?.contact?._id!!
                            )}
                            onClick={() => this.selectedChat(chat)}
                          />
                        ))
                      }
                    </Chats>
                  </>
                }
              </>
            )}
          </Content>
        </ChatList> 
        <ChatMessage
          {...this.props}
          handleLogout={this.logout}
          style={isChatMessage ? { zIndex: 10 } : { zIndex: 0 }}
          chatSelected={chatSelected}
          total={+this.state.total}
          messages={messages}
          handleOnClickBack={() => this.isChatMessage()}
          handleOnClickMoreMessages={() => this.getMoreMessages()}
        />
      </ChatWrapper>

    );
  }
}
