import { format, parseISO } from 'date-fns';
import subDays from "date-fns/addDays";

/**
* DateHelper.tsx
*/
export class DateHelper {

    static getFormatedToday() {
        const dated = new Date();
        return format(dated, 'dd/MM/yyyy');
    }

    static getFormatedYesterday() {
        const dated = new Date();
        subDays(dated, 1);
        return format(dated, 'dd/MM/yyyy');
    }

    static toFormartFromDate(date: Date, formatDate = 'dd/MM/yyyy'): string {
        const dated = (!date ? new Date() : date);
        return format(dated, formatDate);
    }

    static toFormartFromString(dateStr: string, formatDate = 'dd/MM/yyyy'): string {
        const dated = parseISO(dateStr);
        return format(dated, formatDate);
    }

    static toFormartFromMiliseconds(timestamp: number, formatDate = 'dd/MM/yyyy'): string {
        const dated = new Date(timestamp);
        return format(dated, formatDate);
    }

}