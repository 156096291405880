import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider, Modals } from './components/Modal/Modal';
import Routes from './Routes';
import GlobalStyle from './styles/globalStyle';

function App() {
  return (
    <>
      <ModalProvider>
        <Modals />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
        <Routes />
        <GlobalStyle />
        <div id="modal-root"></div>
      </ModalProvider>
    </>
  );
}

export default App;
