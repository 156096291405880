import Service from "../commons/AbstractServices";
import { Channel } from "../models/Channel";

export class ChannelService extends Service<Channel> {

    static service: ChannelService;

    static getInstance(): ChannelService {
        if (!this.service) {
            this.service = new ChannelService();
        }
        return this.service;
    }

    getURLBase() {
        return 'channel/';
    }

    getChannelsByCompany(idCompany: string): Promise<Array<Channel>> {
        return super.getAllById('list/' + idCompany);
    }

    sendSeen(code: string, idChat: string): Promise<void> {
        return super.get(`send/seen/${code}/${idChat}`);
    }

}
