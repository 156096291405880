import { Channel } from "./Channel";
import { Company } from "./Company";
import Contact from "./Contact";

export class Chat {
    _id?: string;
    name?: string;
    company?: Company;
    channel?: Channel;
    contact?: Contact;
    whatsapp?: any;
    lastMessage?: any;
    isGroup?: boolean;
    removed?: boolean;
    avatar?: string;
  
    // timestamps
    created_at?: Date;
    updated_at?: Date;

}
