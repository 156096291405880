import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import { TwitterPicker } from 'react-color';
import * as Yup from 'yup';
import { AbstractComponent } from '../../commons/AbstractComponent';
import Contact from '../../models/Contact';
import Tag from '../../models/Tag';
import { ContactService } from '../../services/ContactService';
import { TagService } from '../../services/TagService';
import { ButtonIcon } from '../LinkIcon/LinkIcon';
import { ModalContext, ModalRemove } from '../Modal/Modal';
import { Nav, NavItem } from '../Navbar/Navbar';
import {
  Button,
  Container,
  Content,
  FormGroup,
  IconCheck,
  InvalidFeedback,
  ListTags,
  PickerContainer,
  Toolbar,
  ButtonGroup,
  ListEmpty
} from './styles';
import listEmpty from './../../assets/images/listEmpty@2x.png';


interface Props {
  contact?: Contact,
  handleClosePage?: (event: React.MouseEvent<HTMLElement>) => void,
  handleClose?: any
}

export default class TagComponent extends AbstractComponent<Props, {}> {

  tagService: TagService = TagService.getInstance();
  contactService: ContactService = ContactService.getInstance();

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  state = {
    name: '',
    description: '',
    color: '',
    tags: [],
    showForm: false
  }

  validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Campo obrigatório')
  })

  componentDidMount = () => {
    this.getTags();
  }

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeComplete = (color: any) => {
    console.log(color.hex);
    this.setState({ color: color.hex });
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    let newTag: Tag = {
      name: this.state.name,
      color: this.state.color || '#000000',
      company: { _id: this.getIDCompanyCurrentUserSession() },
      channel: { _id: this.getIDCurrentChannelSession() }
    };
    this.createTag(newTag);
  }

  getTags = () => {
    this.tagService.getAllById(this.getIDCurrentChannelSession())
      .then((tags: Tag[]) => {
        this.setState({ tags })
      })
  }

  hasChecked = (tag: Tag) => {
    const tagsContact = this.props?.contact?.tags || [];
    return tagsContact.filter((tagContact) => tagContact._id === tag._id).length > 0;
  }

  createTag = (tag: Tag) => {
    this.tagService.create(tag)
      .then(() => {
        this.alertService.success("Operação realizada com sucesso!");
        this.getTags();
        this.resetForm();
        this.setState({ showForm: false })
      })
      .catch((error) => {
        this.alertService.error("erro!")
      })
  }

  resetForm = () => {
    this.setState({
      name: '',
      description: '',
      color: '',
      tags: []
    });
  }

  deleteTag = (id: string) => {
    this.tagService.delete(id)
      .then(() => {
        this.alertService.success("Operação realizada com sucesso!");
        this.getTags();
      })
      .catch((error) => {
        this.alertService.error("erro!")
      })
  }

  addTag = (id: string): void => {
    const tagsContact = this.props?.contact?.tags || [];

    let indexToRemove = -1;
    tagsContact.forEach((tag, index) => {
      if (tag._id === id) {
        indexToRemove = index;
      }
    })

    if (indexToRemove >= 0) {
      tagsContact.splice(indexToRemove, 1);
    } else {
      tagsContact.push({ _id: id });
    }

    this.contactService.updateTags({ _id: this.props?.contact?._id, tags: tagsContact })
      .then(() => {
        this.alertService.success("Operação realizada com sucesso!");
        this.getTags();
      })
      .catch((error) => {
        this.alertService.error("erro!")
      })
  }

  setShowForm = (showForm: boolean) => {
    this.setState({ showForm });
  }

  static contextType = ModalContext;
  context!: React.ContextType<typeof ModalContext>;

  render() {
    const { handleClosePage } = this.props;
    const { tags } = this.state;
    const { handleOpenModal } = this.context

    return (
      <Container>
        <Toolbar>
          <h1>Etiquetas</h1>
          <Nav>
            <NavItem icon='Close' handleOnClick={handleClosePage} />
          </Nav>
        </Toolbar>
        <Content>

          {/* <HeaderDivider>Etiquetas</HeaderDivider> */}
          <ListTags>
            {tags && tags.length === 0 ? (<li>
              <ListEmpty>
              <img src={listEmpty} width="300" alt="Nenhum registro encontrado" />
                <h4>Nenhum registro cadastrado!</h4>
              </ListEmpty>
            </li>) : ''}
            {tags && tags.map((tag: Tag) => (
              <li key={tag._id}>
                <a href="#!" onClick={() => this.addTag(tag._id || '')} style={{ backgroundColor: tag.color }}>
                  <span>{tag.name}</span>
                  {this.hasChecked(tag) && (<IconCheck />)}
                </a>
                <ButtonIcon
                  icon='Close'
                  size={20}
                  padding={3}
                  handOnClick={() => handleOpenModal(
                    <ModalRemove handleModalClick={() => this.deleteTag(tag._id!)} />
                  )} />
              </li>
            ))}
          </ListTags>

          {
            !this.state.showForm && (
              <ButtonGroup>
                <Button primary type="button" onClick={() => this.setShowForm(true)}>Nova Etiqueta</Button>
              </ButtonGroup>
            )
          }

          {
            this.state.showForm && (
              <Formik
                initialValues={this.state}
                validationSchema={this.validationSchema}
                onSubmit={(values, { resetForm }) => {
                  this.setState({ name: values.name });
                  console.log(this.state);
                  let newTag: Tag = {
                    name: this.state.name,
                    color: this.state.color || '#000000',
                    company: { _id: this.getIDCompanyCurrentUserSession() },
                    channel: { _id: this.getIDCurrentChannelSession() }
                  };
                  this.createTag(newTag);
                  resetForm();
                }}>
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup error={errors.name} >
                      <label htmlFor="title">Nome</label>
                      <input
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <InvalidFeedback>
                        <ErrorMessage name="name" />
                      </InvalidFeedback>
                    </FormGroup>
                    <PickerContainer>
                      <TwitterPicker
                        onChangeComplete={this.handleChangeComplete}
                        color={this.state.color} />
                    </PickerContainer>
                    <Button primary type="submit" disabled={isSubmitting}>Salvar</Button>
                  </form>
                )}
              </Formik>
            )
          }
        </Content>
      </Container>

    )
  }
}