import Picker from 'emoji-picker-react';
import React, { createRef, Fragment } from 'react';
import { AbstractComponent } from '../../../commons/AbstractComponent';
import { DateHelper } from '../../../commons/DateHelper';
import { Channel } from '../../../models/Channel';
import { Chat } from '../../../models/Chat';
import Department from '../../../models/Department';
import { Message } from '../../../models/Message';
import { Protocol } from '../../../models/Protocol';
import { User } from '../../../models/User';
import { DepartmentService } from '../../../services/DepartamentService';
import { MessageService } from '../../../services/MessageService';
import { ProtocolService } from '../../../services/ProtocolService';
import Perfil from '../../Perfil/Perfil';
import QuickMessage from '../../QuickMessage/QuickMessage';
import Recorder from '../../RecordeVoicer/RecordeVoice';
import ScheduleMessage from '../../ScheduleMessage/ScheduleMessage';
import Tag from '../../Tag/TagComponent';
import statusConnect from './../../../assets/images/statusConnect.png';
import Avatar from './../../Avatar/Avatar';
import { ButtonIcon, ButtonIconLeft, LinkIcon } from './../../LinkIcon/LinkIcon';
import { Dropdown, DropdownItem, DropdownItemDivider, DropdownItemHeader, DropdownItemText, DropdownListHeader, DropdownListItem, Nav, NavItemDropdown } from './../../Navbar/Navbar';
import ChatMessageItem from './../ChatMessageItem/ChatMessageItem';
import {
  BackButton,
  ChatMessageConversation,
  ChatMessageEmpty, ChatMessageMain,
  ChatMessageWrapper,
  ChatOverview,
  ContainerAttach,
  ContainerEmoji,
  ContainerRecVoice, ContainerSecundaryMenu, DateContainer, MessageActionsContent,
  MessageContent,
  RowMesgAct,
  RowSecundaryMesgAct,
  Toolbar
} from './styles';

type Props = {
  chatSelected: Chat,
  total: number,
  messages: Array<Message>,
  style?: any,
  handleOnClickBack?: any,
  handleOnClickMoreMessages?: any,
  handleLogout?: any
}

export default class ChatMessage extends AbstractComponent<Props, {}> {

  messageService: MessageService = MessageService.getInstance();
  departmentService: DepartmentService = DepartmentService.getInstance();
  protocolService: ProtocolService = ProtocolService.getInstance();
  messagesEndRef = createRef<HTMLLIElement>();
  messagesStartRef = createRef<HTMLLIElement>();
  inputMessage: any;

  constructor(props: Props) {
    super(props);
    this.inputMessage = React.createRef();
  }

  state = {
    user: new User(),
    activeOverview: false,
    showPerfil: false,
    showScheduleMessage: false,
    showQuickMessage: false,
    showTag: false,
    channel: new Channel(),
    chatSelected: new Chat(),
    message: '',
    messages: [],
    departments: [],
    showAudio: false,
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      }
    },
    showEmoji: false,
    showAttachment: false,
    showSecundaryMenu: false,
    file: null,
    scrollTop: false,
    protocol: null
  }

  componentDidMount = () => {
    let user = super.getCurrentUser()
    if (user) {
      this.setState({
        user: super.getCurrentUser(),
        channel: super.getCurrentChannel(),
      });
      this.getDeparments();
    } else {
      this.props.handleLogout();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: any) {
    //console.log(prevProps, prevState);

    if (prevProps.chatSelected._id !== this.props.chatSelected._id) {
      this.setState({ scrollTop: false });
      this.getProtocolOpenedByContact();
    }

    if (this.state.scrollTop) {
      this.scrollToTop();
    } else {
      this.scrollToBottom();
    }
  }

  showOverview = (showPage: any) => {
    this.setState({ activeOverview: true });

    switch (showPage) {
      case 'Perfil':
        this.setState({
          showPerfil: true,
          showScheduleMessage: false,
          showTag: false,
          showQuickMessage: false
        });
        break;
      case 'ScheduleMessage':
        this.setState({
          showPerfil: false,
          showScheduleMessage: true,
          showTag: false,
          showQuickMessage: false
        });
        break;
      case 'QuickMessage':
        this.setState({
          showPerfil: false,
          showScheduleMessage: false,
          showTag: false,
          showQuickMessage: true
        });
        break;
      case 'Tag':
        this.setState({
          showPerfil: false,
          showScheduleMessage: false,
          showTag: true,
          showQuickMessage: false
        });
        break;
    }
  }

  hideOverview = () => {
    this.setState({
      activeOverview: false,
      showPerfil: false,
      showScheduleMessage: false,
      showTag: false,
      showQuickMessage: false
    });
  }

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (this.state.message !== '') {
        this.handleSubmit(event);
      }
    }
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    const bodyMessage = this.state.message;
    this.setState({ submitted: true, message: '', showEmoji: false }, () => {
      const { chatSelected } = this.props;
      const obj = {
        code: this.getCodeCurrentChannelSession(),
        chatId: chatSelected.whatsapp?.id?._serialized || '',
        phone: chatSelected.whatsapp?.id?.user || '',
        message: bodyMessage
      };
      this.messageService.create(obj);
    });
    this.setState({ scrollTop: false });
  }

  getDeparments = () => {
    this.departmentService.getDepartmentsByChannel(this.getIDCurrentChannelSession())
      .then((departments: Department[]) => {
        this.setState({ departments });
      })
  }

  scrollToBottom = () => {
    if (this.messagesEndRef != null)
      this.messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  scrollToTop = () => {
    if (this.messagesStartRef != null)
      this.messagesStartRef?.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  handleAudioStop = (data: any) => {
    console.log(data)
    this.setState({ audioDetails: data });
  }

  handleAudioUpload = (blob: any) => {
    console.log(blob);
    // console.log(this.state.audioDetails);
    let reader = new FileReader();

    reader.onloadend = () => {
      console.log(reader);
      const base64data = reader.result;
      const mimetype = blob.type;
      const base64dataStr: string = `${base64data}`;
      const indexSpit = base64dataStr.indexOf(',');
      const data = base64dataStr.substring(indexSpit + 1, base64dataStr.length);

      console.log(mimetype);
      console.log(data);

      this.setState({ submitted: true, message: '' }, () => {
        const { chatSelected } = this.props;
        const obj = {
          code: this.getCodeCurrentChannelSession(),
          chatId: chatSelected.whatsapp?.id?._serialized || '',
          phone: chatSelected.whatsapp?.id?.user || '',
          type: 'media',
          mimetype: mimetype,
          data: data,
          // body: 
        };
        this.messageService.create(obj);
      });
      return;
    }
    reader.readAsDataURL(blob);
  }

  handleRest = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      }
    }
    this.setState({ audioDetails: reset });
  }

  handleShowAudio = () => {
    this.setState({ showAudio: !this.state.showAudio });
  }

  setShowEmoji = () => {
    this.setState({ showEmoji: !this.state.showEmoji })
  }

  sendEmoji = (e: any, emoji: any) => {
    this.setState({ message: this.state.message + emoji.emoji });
    this.inputMessage.current.focus();
  }

  setShowAttachment = () => {
    this.setState({ showAttachment: !this.state.showAttachment });
  }

  setShowSecundaryMenu = () => {
    this.setState({ showSecundaryMenu: !this.state.showSecundaryMenu });
  }

  getAttachImage = (e: any) => {
    const file: File = e.target.files[0];
    console.log(file);

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      const base64data = reader.result;
      const mimetype = e.target.files[0].type;
      const base64dataStr: string = `${base64data}`;
      const indexSpit = base64dataStr.indexOf(',');
      const data = base64dataStr.substring(indexSpit + 1, base64dataStr.length);
      const filename = file.name;

      // console.log(mimetype);
      // console.log(data);

      this.setState({ submitted: true, message: '' }, () => {
        const { chatSelected } = this.props;
        const obj = {
          code: this.getCodeCurrentChannelSession(),
          chatId: chatSelected.whatsapp?.id?._serialized || '',
          phone: chatSelected.whatsapp?.id?.user || '',
          type: 'media',
          mimetype: mimetype,
          data: data,
          filename: filename
        };
        this.messageService.create(obj);
      });
      return;
    }
  }

  setQuickMessage = (quickMessage: string) => {
    this.setState({ message: this.state.message + quickMessage });
    this.inputMessage.current.focus();
  }

  getLabelDateMessage = (timestamp: number) => {
    const labelDate = DateHelper.toFormartFromMiliseconds(timestamp * 1000);
    const labelToday = DateHelper.getFormatedToday();
    const labelYesterday = DateHelper.getFormatedYesterday();
    if (labelDate === labelToday) {
      return 'Hoje';
    } else if (labelDate === labelYesterday) {
      return 'Antes de ontem';
    } else {
      return labelDate;
    }
  }

  getMoreMessages = () => {
    this.props.handleOnClickMoreMessages();
    this.setState({ scrollTop: true });
  }

  getProtocolOpenedByContact = () => {
    const { chatSelected } = this.props;
    if (chatSelected && chatSelected.contact && chatSelected.contact._id) {
      this.protocolService.getOpenedByContact(chatSelected?.contact?._id!!)
        .then((protocol: Protocol) => {
          // console.log('Protocolo')
          // console.log(protocol)
          this.setState({ protocol })
        })
    }
  }

  transferDepartment = (idDepartment: string) => {
    const { protocol }: any = this.state;
    if (protocol && protocol._id && idDepartment) {
      this.protocolService.transferDepartment(protocol._id, idDepartment)
        .then(() => {
          this.getProtocolOpenedByContact();
        });
    }
  }

  transferOperator = (idUser: string, idDepartment: string) => {
    const { protocol }: any = this.state;
    if (protocol && protocol._id && idUser && idDepartment) {
      this.protocolService.transferOperador(protocol._id, idUser, idDepartment)
        .then(() => {
          this.getProtocolOpenedByContact();
        });
    }
  }

  render() {
    const { chatSelected, handleOnClickBack, messages, style } = this.props;
    const { showAudio, activeOverview, showPerfil, showScheduleMessage, protocol,
      showQuickMessage, showTag, user, channel, departments, showEmoji,
      showAttachment, showSecundaryMenu } = this.state;

    const avatarDefault = "https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/user.jpeg?alt=media";
    return (
      <ChatMessageWrapper style={style}>
        <Toolbar>
          <BackButton onClick={handleOnClickBack}>←</BackButton>
          {chatSelected && chatSelected._id && (
            <Avatar size={35} src={chatSelected?.contact?.image || avatarDefault} />
          )}
          <h1 style={{ marginLeft: '5px' }}>
            {chatSelected?.name} <br />
            <small>{chatSelected?.contact?.number}</small>
          </h1>
          <Nav>
            {chatSelected && chatSelected._id && protocol && (
              <NavItemDropdown icon='UserShared'>
                <Dropdown>
                  {departments && departments.map((department: Department) => {
                    return (
                      <div key={department._id}>
                        <DropdownListHeader
                          handleOnClick={() => this.transferDepartment(department._id || '')}>{department.name}</DropdownListHeader>
                        {department.users && department.users.map((user: User) => {
                          return (
                            <DropdownListItem key={user._id}>
                              <Avatar size={35} src={user?.image || avatarDefault} />
                              <div>
                                <h4>{user.name}</h4>
                                {/* <span>online</span> */}
                              </div>
                              <button onClick={() => this.transferOperator(user._id, department._id || '')}>Transferir</button>
                            </DropdownListItem>
                          )
                        })}
                      </div>
                    )
                  })}
                </Dropdown>
              </NavItemDropdown>
            )}
            {chatSelected && chatSelected._id && (
              <NavItemDropdown icon='DotsVerticalRounded'>
                <Dropdown>
                  <DropdownItem handleOnClick={() => this.showOverview('Perfil')}>
                    Dados do contato
                    </DropdownItem>
                  <DropdownItem handleOnClick={() => this.showOverview('ScheduleMessage')}>
                    Agendamento de mensagem
                    </DropdownItem>
                  <DropdownItem handleOnClick={() => this.showOverview('Tag')}>
                    Adicionar etiqueta
                  </DropdownItem>
                  <DropdownItem handleOnClick={() => this.showOverview('QuickMessage')}>
                    Mensagem rápida
                  </DropdownItem>
                </Dropdown>
              </NavItemDropdown>
            )}
            <NavItemDropdown avatar size={40} src={user.image}>
              <Dropdown>
                <DropdownItemHeader>Usuário</DropdownItemHeader>
                <DropdownItemText>
                  {user.email}
                </DropdownItemText>
                <DropdownItemDivider />
                <DropdownItem handleOnClick={() => this.showOverview('Perfil')}>
                  Dados do contato
                  </DropdownItem>
                <DropdownItemHeader margin={3}>Canal</DropdownItemHeader>
                <DropdownItemText>
                  {channel && channel.code} - {channel.name}
                </DropdownItemText>
                <DropdownItemDivider />
                <DropdownItem
                  handleOnClick={this.props.handleLogout}
                  iconLeft="LogOut" size={16}>
                  Sair
                  </DropdownItem>
              </Dropdown>
            </NavItemDropdown>
          </Nav>
        </Toolbar>
        {chatSelected._id ?
          <ChatMessageMain>
            <ChatMessageConversation activeOverview={activeOverview}>
              <MessageContent>
                {chatSelected && chatSelected._id && (<ul>

                  <li ref={this.messagesStartRef}>
                    <ButtonIconLeft size={32} bgColor='#238C2A' bg='#efefef' icon='Reload' handOnClick={this.getMoreMessages}>Carregar mais...</ButtonIconLeft>
                  </li>
                  
                  {messages.map((message: Message, index: any) => (
                    <Fragment key={message._id}>
                      {
                        (index === 0 || DateHelper.toFormartFromMiliseconds(messages[index - 1]?.timestamp!! * 1000) !== DateHelper.toFormartFromMiliseconds(message?.timestamp!! * 1000)) &&
                        (
                          <DateContainer>
                            <div>
                              <span>{this.getLabelDateMessage(message?.timestamp!!)}</span>
                            </div>
                          </DateContainer>
                        )
                      }
                      {message.fromMe ?
                        <ChatMessageItem odd message={message} /> :
                        <ChatMessageItem message={message} image={chatSelected?.contact?.image} />}
                    </Fragment>
                  ))}
                  <li ref={this.messagesEndRef}></li>
                </ul>)}
              </MessageContent>
              {chatSelected && chatSelected._id &&
                <MessageActionsContent>
                  {/* <RowRecordVoice style={{ display: showAudio ? 'block' : 'none', transform: showAudio ? 'translateY(0)' : 'translateY(500px)' }}>
                  <Recorder
                    hideHeader
                    audioURL={this.state.audioDetails.url}
                    showUIAudio
                    handleAudioStop={(data: any) => this.handleAudioStop(data)}
                    handleAudioUpload={(data: any) => this.handleAudioUpload(data)}
                    handleRest={() => this.handleRest()}
                  />
                </RowRecordVoice> */}
                  <RowSecundaryMesgAct bgColor="#fff">
                    <div>
                      <ButtonIconLeft size={26} bgColor='#1835D9' bg='#F3F7F9' icon='Power' handOnClick={() => this.showOverview('QuickMessage')}>Mensagem rápida</ButtonIconLeft>
                      <ButtonIconLeft size={26} bgColor='#BF0B3B' bg='#F3F7F9' icon='TagAlt' handOnClick={() => this.showOverview('Tag')}>Etiquetas</ButtonIconLeft>
                      <ButtonIconLeft size={26} bgColor='#238C2A' bg='#F3F7F9' icon='Calendar' handOnClick={() => this.showOverview('ScheduleMessage')}>Agendamento Mensagens</ButtonIconLeft>
                    </div>
                    <div>
                    </div>
                    <div>

                    </div>
                  </RowSecundaryMesgAct>
                  <RowMesgAct>
                    <div>
                      <LinkIcon hiddenDesktop bgColor='#fff' color='#454A5' icon='DotsVerticalRounded' handOnClick={() => this.setShowSecundaryMenu()} />
                      <ContainerSecundaryMenu onMouseLeave={this.setShowSecundaryMenu} style={{ display: showSecundaryMenu ? 'block' : 'none' }}>
                        <ButtonIconLeft size={26} bgColor='#1835D9' bg='#F3F7F9' icon='Power' handOnClick={() => this.showOverview('QuickMessage')}>Mensagem rápida</ButtonIconLeft>
                        <ButtonIconLeft size={26} bgColor='#BF0B3B' bg='#F3F7F9' icon='TagAlt' handOnClick={() => this.showOverview('Tag')}>Etiquetas</ButtonIconLeft>
                        <ButtonIconLeft size={26} bgColor='#238C2A' bg='#F3F7F9' icon='Calendar' handOnClick={() => this.showOverview('ScheduleMessage')}>Agendamento Mensagens</ButtonIconLeft>
                      </ContainerSecundaryMenu>
                      <LinkIcon icon='Emoji' bgColor='#F2B90C' handOnClick={() => this.setShowEmoji()} />
                      <ContainerEmoji onMouseLeave={this.setShowEmoji} style={{ display: showEmoji ? 'block' : 'none' }}>
                        <Picker
                          onEmojiClick={this.sendEmoji}
                          disableSearchBar
                          groupNames={{
                            smileys_people: 'Emoji e Pessoas',
                            animals_nature: 'Animais e Natureza',
                            food_drink: 'Comida e Bebida',
                            travel_places: 'Viagens e Locais',
                            activities: 'Atividade',
                            objects: 'Objetos',
                            symbols: 'Símbolos',
                            flags: 'Bandeiras',
                            recently_used: 'Recentes',
                          }} />
                      </ContainerEmoji>
                      <LinkIcon icon='Paperclip' handOnClick={() => this.setShowAttachment()} />
                      <ContainerAttach onMouseLeave={this.setShowAttachment} style={{ display: showAttachment ? 'block' : 'none' }}>
                        <ButtonIconLeft size={26} bgColor='#8A29BE' icon='Power' handOnClick={() => document.getElementById('attachDocument')?.click()}>Documento</ButtonIconLeft>
                        <ButtonIconLeft size={26} bgColor='#8A29BE' icon='Image' handOnClick={() => document.getElementById('attachImage')?.click()}>Imagem</ButtonIconLeft>
                        <input type="file" id="attachImage" onChange={this.getAttachImage} hidden accept="image/*" />
                        <input type="file" id="attachDocument" onChange={this.getAttachImage} hidden accept="application/pdf, text/plain, application/x-7z-compressed, application/zip, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet " />
                      </ContainerAttach>
                    </div>
                    <input
                      type="text"
                      id="message"
                      name="message"
                      ref={this.inputMessage}
                      value={this.state.message}
                      placeholder="Digite sua mensagem..."
                      onKeyDown={this.onKeyDown}
                      onChange={this.handleChange} />
                    <div>
                      {this.state.message && <ButtonIcon bgColor='#1abc9c' icon='Plane' handOnClick={this.handleSubmit} />}
                      {!this.state.message && <ButtonIcon icon='Microphone' handOnClick={this.handleShowAudio} />}
                      <ContainerRecVoice onMouseLeave={this.handleShowAudio} style={{ display: showAudio ? 'block' : 'none' }}>
                        <Recorder
                          hideHeader
                          audioURL={this.state.audioDetails.url}
                          showUIAudio
                          handleAudioStop={(data: any) => this.handleAudioStop(data)}
                          handleAudioUpload={(data: any) => this.handleAudioUpload(data)}
                          handleRest={() => this.handleRest()}
                        />
                      </ContainerRecVoice>
                    </div>
                  </RowMesgAct>
                </MessageActionsContent>}
            </ChatMessageConversation>
            <ChatOverview activeOverview={activeOverview}>
              {showScheduleMessage &&
                <ScheduleMessage
                  contact={chatSelected.contact}
                  handleClosePage={this.hideOverview} />}
              {showPerfil &&
                <Perfil
                  chat={chatSelected}
                  handleClosePage={this.hideOverview} />}
              {showTag &&
                <Tag
                  contact={chatSelected.contact}
                  handleClosePage={this.hideOverview} />}
              {showQuickMessage &&
                <QuickMessage
                  contact={chatSelected.contact}
                  handleClosePage={this.hideOverview}
                  handleQuickMessage={this.setQuickMessage} />}
            </ChatOverview>
          </ChatMessageMain> :
          <ChatMessageEmpty>
            <img src={statusConnect} width="300" alt="Nenhum chat encontrado" />
            <h2>Mantenha seu celular conectado!</h2>
            <p>O WhatsApp conecta ao seu telefone para sincronizar suas mensagens.</p>
            <p>Para reduzir o uso de dados, conecte seu telefone a uma rede Wi-Fi.</p>
          </ChatMessageEmpty>}
      </ChatMessageWrapper>
    )
  }
}
