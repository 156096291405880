import Service from "../commons/AbstractServices";
import Department from "../models/Department";

export class DepartmentService extends Service<Department> {

    static service: DepartmentService;

    static getInstance(): DepartmentService {
        if (!this.service) {
            this.service = new DepartmentService();
        }
        return this.service;
    }

    getURLBase() {
        return 'department/';
    }
    
    getDepartmentsByChannel(idChannel: string): Promise<Array<Department>> {
        return super.getAllById('list/' + idChannel);
    }

}
