import Service from "../commons/AbstractServices";
import { User } from "../models/User";

export class UserService extends Service<User> {

    static service: UserService;

    static getInstance(): UserService {
        if (!this.service) {
            this.service = new UserService();
        }
        return this.service;
    }

    getURLBase() {
        return 'user/';
    }

    updateToken(id: string, token: string) {
        return this.post(`update/token/${id}`, { token });
    }

}
