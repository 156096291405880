import React from 'react'

import {
  IconContainer, Link, IconSearch, IconUserShared, IconDotsVerticalRounded,
  IconPower, IconPlane, IconPaperclip, IconCloseOutline, IconCheck, Button,
  IconPhone, IconMicrophone, IconEmail, IconPurchaseTag, IconTagAlt, IconTag,
  IconBookmark, ButtonTextLeft, IconCalendar, IconEmoji, IconImage, IconReload
} from './styles';

interface Props {
  icon: string,
  children?: React.ReactNode | React.ReactNode[],
  bg?: string,
  bgColor?: string,
  color?: string,
  size?: number,
  padding?: number,
  onClick?: any,
  handOnClick?: any,
  hiddenDesktop?: boolean
}

const getIcon = (icon: string) => {
  switch (icon) {
    case 'Search':
      return <IconSearch />
    case 'UserShared':
      return <IconUserShared />
    case 'DotsVerticalRounded':
      return <IconDotsVerticalRounded />
    case 'Power':
      return <IconPower />
    case 'Plane':
      return <IconPlane />
    case 'Paperclip':
      return <IconPaperclip />
    case 'Close':
      return <IconCloseOutline />
    case 'Check':
      return <IconCheck />
    case 'Phone':
      return <IconPhone />
    case 'Microphone':
      return <IconMicrophone />
    case 'Email':
      return <IconEmail />
    case 'PurchaseTag':
      return <IconPurchaseTag />
    case 'TagAlt':
      return <IconTagAlt />
    case 'Tag':
      return <IconTag />
    case 'Bookmark':
      return <IconBookmark />
    case 'Calendar':
      return <IconCalendar />
    case 'Emoji':
      return <IconEmoji />
    case 'Image':
      return <IconImage />
    case 'Reload':
        return <IconReload />
    default:
      return '';
  }
}

export function LinkIcon(props: Props) {
  return (
    <Link {...props} onClick={props.handOnClick}>
      <IconContainer {...props} >{getIcon(props.icon)}</IconContainer>
      {props.children}
    </Link>
  )
}

export function ButtonIcon(props: Props) {
  return (
    <Button {...props} onClick={props.handOnClick}>
      <IconContainer {...props} >{getIcon(props.icon)}</IconContainer>
      {props.children}
    </Button>
  )
}

export function ButtonIconLeft(props: Props) {
  return (
    <ButtonTextLeft {...props} onClick={props.handOnClick}>
      <IconContainer {...props} >{getIcon(props.icon)}</IconContainer>
      {props.children}
    </ButtonTextLeft>
  )
}

export function Icon(props: Props) {
  return (
    <IconContainer>{getIcon(props.icon)}</IconContainer>
  )
}
