import Service from "../commons/AbstractServices";
import Tag from "../models/Tag";

export class TagService extends Service<Tag> {

    static service: TagService;

    static getInstance(): TagService {
        if (!this.service) {
            this.service = new TagService();
        }
        return this.service;
    }

    getURLBase() {
        return 'tag/';
    }
    
    get(id: string): Promise<Array<Tag>> {
        return super.getById(id);
    }

    getAllById(id: string): Promise<Array<Tag>> {
        return super.getAllById('list/' + id);
    }
}
