import styled from 'styled-components';

export interface Props {
  muted?: boolean,
  textAlign?: string,
  primary?: boolean,
  error?: string
}

export const ContainerLogin = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export const ContentLogin = styled.div`
  width: 100%;
  background-color: var(--white);
  padding: 3.6rem;

  @media (min-width: 700px) {
    width: 45rem;
  }

  header {
    text-align: center;
  }
`;

export const FormLogin = styled.form``;

export const FormGroup = styled.div`

  margin-bottom: 3rem;

  label {
    font-size: 1.4rem;
    display: block;
    margin-bottom: 1rem;
    color: ${(props: Props) => props.error ? 'var(--danger)' : 'var(--secondary)'};
  }

  input {
    display: block;
    width: 100%;
    height: calc(1.5em + .9rem + 2px);
    padding: 1.7rem 1.2rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--secondary);
    background-color: var(--white);
    background-clip: padding-box;
    border: ${(props: Props) => props.error ? '1px solid red' : '1px solid #ced4da'};
    border-radius: .5rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    

    &:focus {
      color: #495057;
      background-color: var(--white);
      border-color: #b1bbc4;
      outline: 0;
      box-shadow: none;
    }

    &::placeholder{
        font-size: 1.4rem;
        color: #adb5bd;
        opacity: 1
    }
  }
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: .5rem;
  font-size: 1.2rem;
  color: var(--danger);
`;

export const Paragraph = styled.p`
  color: ${(props: Props) => props.muted ? 'var(--gray)' : 'var(--secondary)'};
  text-align: ${(props: Props) => props.textAlign};
  margin: 2rem 5rem 3rem;
  font-size: 1.4rem;
`;

export const ParagraphMuted = styled(Paragraph)`
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + .9rem + 2px);
  padding: 1.7rem 1.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--secondary);
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: 1.5rem;

  &:focus {
    color: #495057;
    background-color: var(--white);
    border-color: #b1bbc4;
    outline: 0;
    box-shadow: none;
  }

  &::placeholder{
      font-size: 1.4rem;
      color: #adb5bd;
      opacity: 1
  }
`;

export const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: .5rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;

  background-color: ${(props: Props) => props.primary ? 'var(--primary)' : 'var(-dark)'};
  border-color: ${(props: Props) => props.primary ? '#0f222d' : '#dee2e6'};
  color: ${(props: Props) => props.primary ? '#fff' : '#dee2e6'};

  width: 100%;
  padding: 1rem;
`;

export const BtnLink = styled.a`
    font-size: 1.4rem;
    font-weight: 300;
    color: #0f222d;
    background-color: transparent;
    display: block;
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
`;