import { Channel } from "./Channel";
import { Company } from "./Company";
import Tag from "./Tag";

export default class Contact {

  public _id?: string;
  public name?: string;
  public email?: string;
  public number?: string;
  public image?: string;
  public origin?: Tag;
  public kindOf?: Tag;

  public company?: Company;
  public channel?: Channel;
  public tags?: Array<Tag>;

  public whatsapp?: object;

  public removed?: boolean;

  // timestamps
  public created_at?: Date;
  public updated_at?: Date;

}