import styled from 'styled-components';

import {Search} from '@styled-icons/material'

interface Props {
  size?: number
}

export const ChatWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  align-items: stretch;

  width: 100vw;
  height: 100vh;

  @media (min-width: 700px) {
    grid-template-columns: 25vw auto;
  }
`;

export const ChatList = styled.div`
  background-color: var(--white);
  grid-column: 1;
  grid-row: 1; 
  z-index: 1;
  display: grid;
  grid-template-rows: 70px auto;

  @media (min-width: 600px) {
    grid-column: 1;
    z-index: 1;
  }
`;

export const Toolbar = styled.div`
  background-color: var(--primary);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-rows: 8vh 84vh;
`;

export const InputSearch = styled.div`

  position: relative;
  padding: 1.3rem 2rem; 

  input {
    width: 100%;
    padding: 1.5rem 5rem;
    color: var(--secondary);
    background-color: #F3F7F9;
    border-radius: 2rem;
    border: 0;
    font-size: 1.6rem;

    &::placeholder {
      color: var(--gray);
    }
  }
`;

export const Icon = styled(Search)`
    position: absolute;
    z-index: 10;
    font-size: ${(props: Props) => props.size ? `${props.size}px` : '16px'};
    line-height: calc(1.5em + .9rem + 2px);
    left: 35px;
    top: 25px;
    color: #98a6ad;
`;

export const Chats = styled.ul`
  list-style: none;
  overflow: scroll;
  height: auto;
`;

export const ChatEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 95vh;

  img {
    margin-bottom: 2rem;
  }
`;

