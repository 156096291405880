
import * as firebase from "firebase/app";
import "firebase/messaging";
import {isSafari} from "react-device-detect";


const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDwaQRKkoYjB2wABnzOyTTdsyKloHSO-LA",
  authDomain: "zapteck-2707e.firebaseapp.com",
  databaseURL: "https://zapteck-2707e.firebaseio.com",
  projectId: "zapteck-2707e",
  storageBucket: "zapteck-2707e.appspot.com",
  messagingSenderId: "1065124905487",
  appId: "1:1065124905487:web:151104c20ff06dcab2197a",
  measurementId: "G-JHRXV6S0LG"
});


let messaging: any;
if(!isSafari) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    "BM6YHB9AvzZmrSIl35Pr9abjTGPF_Re4em6lxZYKsvFd1J0FH69PPyVDzz9A74PUbjjDavx02u2NgtxL1BQlisg"
  );
} else {
  messaging = '';
}

export { messaging };
