import { ErrorMessage, Formik } from 'formik';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { Constants } from '../../commons/Contants';
import Logo from '../../components/Logo/Logo';
import { Channel } from '../../models/Channel';
import { AlertService } from '../../services/AlertService';
import { AuthenticationService } from '../../services/AuthService';
import { ChannelService } from '../../services/ChannelService';
import {
  BtnLink,
  Button,
  ContainerLogin,
  ContentLogin,
  FormGroup,
  FormLogin,
  InvalidFeedback,
  Paragraph,
  ParagraphMuted
} from './styles';

export default class LoginScreen extends Component<RouteComponentProps> {

  alertService: AlertService = AlertService.getInstance();
  authenticationService: AuthenticationService = AuthenticationService.getInstance();
  channelService: ChannelService = ChannelService.getInstance();

  state = {
    email: '',
    password: '',
    loading: false,
    returnUrl: '',
    submitted: false
  }

  loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail invalido')
      .required('Campo obrigatório'),
    password: Yup.string()
      .required('Campo obrigatório')
  })

  login = () => {
    this.authenticationService.login(this.state.email, this.state.password)
      .then((data: any) => {
        const idCompany = data.user.company._id;
        return this.channelService.getChannelsByCompany(idCompany);
      }).then((channels: Channel[]) => {
        if (!channels || channels.length === 0) {
          this.alertService.warning('Usuário sem canal.');
        }
        localStorage.setItem(Constants.CURRENT_CHANNEL, JSON.stringify(channels[0]));
      }).then(() => {
        this.props.history.push(`/chats`);
      }).catch((err: any) => {
        this.alertService.error(err);
      });
  }

  render = () => {
    return (
      <ContainerLogin>
        <ContentLogin>
          <header>
            <Logo height="100" />
            <Paragraph muted textAlign="center">Entre com seu endereço de email e senha para acessar o painel.</Paragraph>
          </header>
          <Formik
            initialValues={this.state}
            validationSchema={this.loginSchema}
            onSubmit={(values) => {
              this.setState(values);
              this.login();
            }}>
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
                <FormLogin onSubmit={handleSubmit}>
                  <FormGroup error={errors.email} >
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="email@dominio.com.br"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <InvalidFeedback>
                      <ErrorMessage name="email" />
                    </InvalidFeedback>
                  </FormGroup>
                  <FormGroup error={errors.password}>
                    <label htmlFor="password">Senha</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="*****"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password} />
                    <InvalidFeedback>
                      <ErrorMessage name="password" />
                    </InvalidFeedback>
                  </FormGroup>
                  <Button primary type="submit">Entrar</Button>
                </FormLogin>
              )}
          </Formik>

          <BtnLink href="#">Esqueci minha Senha</BtnLink>
          <ParagraphMuted textAlign="center">Desenvolvido por Trendbuild Soluções Digitais</ParagraphMuted>
        </ContentLogin>
      </ContainerLogin>
    );
  }
};
