import React from 'react';
import UtilHelper from '../../../commons/UtilHelper';
import { Chat } from '../../../models/Chat';
import { Protocol } from '../../../models/Protocol';
import Avatar from '../../Avatar/Avatar';
import {
  Badge,
  BadgeNoMargin,
  ChatItemContainer,
  ChatItemLink,
  Column,
  Details,
  LabelMessage,
  LastMessage,
  NameText,
  TimeMessage
} from './styles';

export interface Props {
  chat: Chat,
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  protocol?: Protocol
}

const avatar = "https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/user.jpeg?alt=media";

export const ChatItem = (props: Props) => {
  const { chat, onClick, protocol } = props;
  return (
    <ChatItemContainer>
      <ChatItemLink href="#" onClick={onClick}>
        <Avatar src={chat.contact?.image || avatar} size={55} />
        <Details>
          <Column>
            <NameText>{chat.name}</NameText>
            <LastMessage>
              {chat?.lastMessage && (
                chat?.lastMessage?.body.length > 30 ? chat?.lastMessage?.body.substring(0, 30) + '...' : chat?.lastMessage?.body
              )}
            </LastMessage>
            <LastMessage>
              {chat.contact?.kindOf && (
                <Badge key={chat.contact?.kindOf._id} color="#fff" bg={chat.contact?.kindOf.color}>{chat.contact?.kindOf.name}</Badge>
              )}
            </LastMessage>
          </Column>
          <Column right>
            <TimeMessage>{UtilHelper.getTimeFormated(chat.whatsapp.timestamp)}</TimeMessage>
            {
              chat.whatsapp.unreadCount > 0 &&
              (<LabelMessage>{chat.whatsapp.unreadCount}</LabelMessage>)
            }
            <BadgeNoMargin color="#333" bg="#efefef">
              {
                (protocol && (protocol.operator ? protocol.operator.name : [protocol.department?.name]) )
              }
            </BadgeNoMargin>
          </Column>
        </Details>
      </ChatItemLink>
    </ChatItemContainer>
  )
}